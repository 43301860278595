import React from 'react'
import FeatherIcon from 'feather-icons-react'
export default function HeaderForm(props) {
    return (
        <div className="row px-3">
            <div className="col-3">
                <button
                    type="button"
                    className={props.moreOptions === 'ParticipantInfo' ? 'w-100 tab more-options current-tab' : props.info_form ? 'w-100 tab more-options' : 'w-100 tab more-options invalid-tab'}
                    data-index="ParticipantInfo"
                    onClick={props.handleMoreOptions}>
                    <FeatherIcon size="17px" icon="user" className="icon" /> <span className="icon required">*</span>
                </button>
            </div>
            <div className="col-3">
                <button
                    type="button"
                    className={
                        props.moreOptions === 'ContactParticipantInfo' ? 'w-100 tab more-options current-tab' : props.info_form ? 'w-100 tab more-options' : 'w-100 tab more-options invalid-tab'
                    }
                    data-index="ContactParticipantInfo"
                    onClick={props.handleMoreOptions}>
                    <FeatherIcon size="17px" icon="hash" className="icon" />
                </button>
            </div>
            <div className="col-3">
                <button
                    type="button"
                    className={props.moreOptions === 'Address' ? 'w-100 tab more-options current-tab' : props.address_form ? 'w-100 tab more-options' : 'w-100 tab more-options invalid-tab'}
                    data-index="Address"
                    onClick={props.handleMoreOptions}>
                    <FeatherIcon size="17px" icon="map-pin" className="icon" />
                </button>
            </div>
            <div className="col-3">
                <button
                    type="button"
                    className={props.moreOptions === 'ContactList' ? 'w-100 tab more-options current-tab' : props.contact_form ? 'w-100 tab more-options' : 'w-100 tab more-options invalid-tab'}
                    data-index="ContactList"
                    onClick={props.handleMoreOptions}>
                    <FeatherIcon size="17px" icon="briefcase" className="icon" />
                </button>
            </div>
        </div>
    )
}
