export const AddPermission = (state, action) => {
    let add = action.data;
    let permissions = state.permissions
    for(let x =0;x<add.length;x++){
        permissions[add[x]]=true
    }
    return Object.assign({}, state, { permissions })
}
export const RemovePermission = (state, action) => {
    let remove = action.data;
    let permissions = state.permissions
    for(let x =0;x<remove.length;x++){
        permissions[remove[x]]=false
    }
    return Object.assign({}, state, { permissions })
}
