import React from 'react'
import styled from 'styled-components'
import FeatherIcon from 'feather-icons-react'
import { Position, Popover } from '@blueprintjs/core'
import NotificationCard from './NotificationCard'
import { GetNotifications, ClearNotifications } from '../../API/notifications'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const uid_user = cookies.get('uid')
const token = cookies.get('token')
export default function NotificationsBell(props) {
    const [notifications, setNotifications] = React.useState([])
    const [count_unread, setCountUnread] = React.useState(0)

    const initData = async () => {
        setTimeout(async () => {
            let response = await GetNotifications('?page_size=200')
            setCountUnread(response.data.count_unread)
            setNotifications(response.data.results)
        }, 1000)
    }

    React.useEffect(() => {
        initData()
    }, [])

    const roomName = uid_user

    const chatSocket = new WebSocket(
        !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
            // ? `ws://localhost:8001/ws/notifications/${roomName}/?token=${token}`
            ? `wss://socket.serviarquitectura.com/ws/notifications/${roomName}/?token=${token}`
            : `wss://socket.serviarquitectura.com/ws/notifications/${roomName}/?token=${token}`
    )

    chatSocket.onmessage = function (e) {
        const data = JSON.parse(e.data)
        let notifications_ = Array.from(notifications)
        notifications_.push(data)
        setNotifications(notifications_)
        setCountUnread(count_unread + 1)
    }

    chatSocket.onclose = function (e) {
        console.error('Chat socket closed unexpectedly')
    }

    const clearNotification = async () => {
        await ClearNotifications()
        let response = await GetNotifications('?page_size=200')
        setCountUnread(response.data.count_unread)
        setNotifications(response.data.results)
    }

    return (
        <Popover className="bp3-popover-content-sizing  mt-1" position={Position.BOTTOM}>
            <a>
                <ContentBell>
                    <FeatherIcon icon="bell" size="18" stroke="#000" />
                    {count_unread > 0 && <BadgeCount>{count_unread}</BadgeCount>}
                </ContentBell>
            </a>
            <div>
                <Div className="w-100">
                    {typeof notifications !== 'undefined' && notifications.length > 0 && (
                        <div className="w-100 text-right">
                            <ClearNotificationsBtn onClick={clearNotification}>
                                <small>Limpiar notificaciones</small>
                            </ClearNotificationsBtn>
                        </div>
                    )}
                    <Separtor />
                    {typeof notifications !== 'undefined' &&
                        notifications.length > 0 &&
                        notifications.map((e, key) => <NotificationCard notification={e} key={key} count_unread={count_unread} setCountUnread={setCountUnread} />)}
                    {typeof notifications !== 'undefined' && notifications.length == 0 && <NotificationCard_>No hay notificaciones.</NotificationCard_>}
                </Div>
            </div>
        </Popover>
    )
}

const Div = styled.div`
    max-height: 28vh;
    overflow-y: auto;
`

const NotificationCard_ = styled.div`
    width: 300px;
    padding: 30px 0;
    text-align: center;
    border-bottom: solid 1px #eee;
`

const BadgeCount = styled.span`
    position: absolute;
    width: 15px;
    height: 15px;
    color: #fff;
    text-align: center;
    font-size: 10px;
    background-color: #e03f4d;
    top: -7px;
    right: -7px;
    border-radius: 50%;
`
const ContentBell = styled.div`
    position: relative;
`

const ClearNotificationsBtn = styled.button`
    margin: 0;
    padding: 5px;
    border: none;
    color: #666;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    background-color: #fff;
`

const Separtor = styled.div`
    width: 100%;
    height: 1px;
    background-color: #eee;
`
