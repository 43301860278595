import React, { Component } from 'react'
import './App.css'
import Container from './Layouts/Container'
import './static/css/styles.css'
import './static/css/components.css'
import 'codemirror/lib/codemirror.css'
import 'codemirror/keymap/sublime'
import 'codemirror/theme/monokai.css'
import 'codemirror/theme/idea.css'
import store from './Store/store'
import { Provider } from 'react-redux'

class App extends Component {
    render() {
        return (
            <div className="App">
                <Provider store={store}>
                    <Container />
                </Provider>
            </div>
        )
    }
}

export default App
