export const ChangeTab = (state, action) => {
    let currentTab = action.currentTab
    return Object.assign({}, state, { currentTab })
}
export const SetCurrent = (state, action) => {
    let current = action.current
    return Object.assign({}, state, { current, currentTab: '1' })
}
export const SetValues = (state, action) => {
    let { invoices, projects, estates, contacts, budgets, participants } = action
    let state_ = action.state
    return Object.assign({}, state, { invoices, projects, estates, contacts, budgets, participants, state: state_ })
}
