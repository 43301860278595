import React, { Component } from 'react'
import Topbar from './Topbar'
import Content from './Content'
import Sidebar from './Sidebar'
import Login from '../Pages/Login/index'
import { connect } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

const getState = (state) => {
    return {
        login: state.login,
        permissions: state.permissions,
    }
}
const Dispatch = (dispatch) => {
    return {
        Login: (data) => {
            const action = {
                type: 'Login',
                data: data,
            }
            dispatch(action)
        },
    }
}
class Container extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return this.props.login ? (
            <BrowserRouter>
                <div>
                    <Topbar />
                    <Content permissions={this.props.permissions} />
                    <Sidebar />
                </div>
            </BrowserRouter>
        ) : (
            <Login />
        )
    }
}

export default connect(getState, Dispatch)(Container)
