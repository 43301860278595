import React from 'react'
import { GetContacts } from '../../API/Participants'
import Select from 'react-select'
const customStyles = {
    dropdownIndicator: (provided, state) => ({
        display: 'none',
    }),
    loadingIndicator: (provided, state) => ({
        display: 'none',
    }),
    indicatorSeparator: (provided, state) => ({
        display: 'none',
    }),
}

export default function ContactParticipantField(props) {
    const [contacts, setContacts] = React.useState([])

    const loaddata = async (participant) => {
        try {
            let response = await GetContacts('parent=' + participant)
            setContacts(response.data.results)
        } catch (err) {
            console.log(err)
        }
    }

    React.useEffect(() => {
        loaddata(props.participant)
    }, [props.participant])

    return (
        <Select
            classNamePrefix="react-select"
            placeholder=""
            noOptionsMessage={() => null}
            isMulti={props.isMulti}
            styles={customStyles}
            onChange={props.onChange}
            options={contacts}
            id={props.id}
            value={props.value}
            isDisabled={props.disabled}
            getOptionLabel={(opt) => opt.fullName}
            getOptionValue={(opt) => opt.id}
        />
    )
}
