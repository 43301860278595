import React, { Component } from 'react'
import inconSalir from '../static/images/icon/icon-salir-dark.png'
import { connect } from 'react-redux'
import Cookies from 'universal-cookie'
import SidebarMobile from './SidebarMobile'
import ModalParticipant from '../CardTop/ModalForm/ModalParticipant'
import SectionsHeader from './SectionsHeader'
import { withRouter } from 'react-router-dom'
import { Drawer, Position } from '@blueprintjs/core'
import NotificationsBell from '../Components/Topbar/NotificationsBell'
import styled from 'styled-components'
import { Classes, Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core'
import AvatarImg from '../static/images/avatar.png'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

const cookies = new Cookies()
const getState = (state) => {
    return {
        current: state.current,
        invoices: state.invoices,
        projects: state.projects,
        estates: state.estates,
        participants: state.participants,
        contacts: state.contacts,
        budgets: state.budgets,
        state: state.state,
        currentTab: state.currentTab,
        cid: state.cid,
    }
}

const Dispatch = (dispatch) => {
    return {
        Logout: () => {
            const action = {
                type: 'Logout',
                open: false,
            }
            dispatch(action)
        },
        changeTab: (e) => {
            const action = {
                type: 'ChangeTab',
                currentTab: e.target.getAttribute('data-tab'),
            }
            dispatch(action)
        },
        setCurrent: () => {
            const action = {
                type: 'SetCurrent',
                tab: '',
                current: null,
            }
            dispatch(action)
        },
    }
}

class Topbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: cookies.get('email', { path: '/' }),
            uid: cookies.get('uid', { path: '/' }),
            open: false,
            modalParticipant: false,
        }
    }
    _openDrawer = () => {
        this.setState({ open: true })
    }
    _closeDrawer = () => {
        this.setState({ open: false })
    }
    _openModalParticipant = () => {
        this.setState({ modalParticipant: true })
    }
    _closeModalParticipant = () => {
        this.setState({ modalParticipant: false })
    }

    toggle = (value) => {
        this.setState({ showShare: value })
    }

    render() {
        return (
            <div className="profile-bar row" style={{ marginLeft: 0, marginRight: 0 }}>
                <Drawer transitionDuration={1000} size={Drawer.SIZE_SMALL} position={Position.LEFT} canOutsideClickClose onClose={this._closeDrawer} isOpen={this.state.open}>
                    <SidebarMobile onClose={this._closeDrawer} />
                </Drawer>

                <div className="col-1 text-left profile">
                    <a className="icon-menu" onClick={this._openDrawer}>
                        <i className="fas fa-bars"></i>
                    </a>
                </div>

                <div className="col-8">
                    <SectionsHeader
                        changeTab={this.props.changeTab}
                        setCurrent={this.props.setCurrent}
                        location={this.props.location}
                        history={this.props.history}
                        current={this.props.current}
                        currentTab={this.props.currentTab}
                        invoices={this.props.invoices}
                        projects={this.props.projects}
                        estates={this.props.estates}
                        participants={this.props.participants}
                        contacts={this.props.contacts}
                        budgets={this.props.budgets}
                        state={this.props.state}
                    />
                </div>
                <ContentActions className="col-3 text-right actions-topbar">
                    <NotificationsBell uid={this.state.uid} />
                    <Popover portalClassName="foo" enforceFocus={false}>
                        <Avatar src={AvatarImg} />

                        <Menu className={Classes.ELEVATION_1}>
                            <MenuItem icon="share" text={'Ver código'} onClick={() => this.toggle(true)} />
                            <MenuDivider />
                            <MenuItem onClick={this.props.Logout} icon={<img style={{ width: '18px' }} src={inconSalir} />} text="Salir" />
                        </Menu>
                    </Popover>
                    {/* <Icon  className="icon-profile-bar salir"></Icon> */}
                    {/* <a onClick={this._openModalParticipant}>
                            <i className="pointer-ignore fas fa-user-plus"></i>
                        </a> */}
                    <ModalParticipant open={this.state.modalParticipant} onClose={this._closeModalParticipant} />
                    <Modal
                        isOpen={this.state.showShare}
                        toggle={() => {
                            this.toggle(false)
                        }}>
                        <ModalHeader
                            toggle={() => {
                                this.toggle(false)
                            }}>
                            Código para compartir
                        </ModalHeader>
                        <ModalBody>
                            <InputText className="form-control" type="text" value={this.props.cid} readOnly />
                            <small>Haz click para copiar el código.</small>
                            <br />
                            <br />
                            <small>
                                <strong style={{ color: '#F00' }}> Nota:</strong> Este código podrás compartirlo con otros <strong>usuarios externos</strong> a tu empresa, que utilicen la plataforma
                                para que puedan compartirte información de participantes, propiedades, contactos, etc... y la tengas disponible para tu uso personal.
                            </small>
                        </ModalBody>
                        <ModalFooter>
                            {' '}
                            <Button
                                color="secondary"
                                onClick={() => {
                                    this.toggle(false)
                                }}>
                                Cerrar
                            </Button>
                        </ModalFooter>
                    </Modal>
                </ContentActions>
            </div>
        )
    }
}

export default withRouter(connect(getState, Dispatch)(Topbar))

const Icon = styled.a`
    margin-left: 10px;
`

const InputText = styled.input`
    cursor: pointer;
`

const Avatar = styled.img`
    border-radius: 50%;
    float: right;
    right: 0;
    width: 25px;
    height: 25px;
    margin-left: 15px;
    cursor: pointer;
`

const ContentActions = styled.div`
    display: flex;
    align-content: center;
    justify-content: flex-end;
`
