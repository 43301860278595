import { createStore } from 'redux'
import { Login, Logout } from './Reducers/LoginReducer'
import { handleSidebar } from './Reducers/MenuReducer'
import { changeList } from './Reducers/ListReducer'
import { ChangeTab, SetCurrent, SetValues } from './Reducers/DetailViews'
import { AddPermission, RemovePermission } from './Reducers/Permissions'

const state = {
    login: false,
    permissions: {},
    showSidebar: false,
    collapse: false,
    changeListResult: 0,
    current: null,
    invoices: 0,
    projects: 0,
    estates: 0,
    contacts: 0,
    budgets: 0,
    state: 0,
    participants: 0,
    currentTab: null,
    serieInvoice: null,
    serieBudget: null,
    serieRectified: null,
    cid: null,
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'Login':
            return Login(state, action)
        case 'ChangeTab':
            return ChangeTab(state, action)
        case 'SetValues':
            return SetValues(state, action)
        case 'SetCurrent':
            return SetCurrent(state, action)
        case 'Logout':
            return Logout(state, action)
        case 'handleSidebar':
            return handleSidebar(state, action)
        case 'changeList':
            return changeList(state, action)
        case 'AddPermission':
            return AddPermission(state, action)
        case 'RemovePermission':
            return RemovePermission(state, action)
        default:
            break
    }
    return state
}

const store = createStore(reducer, state)

export default store
