import axios from 'axios'

export const SERVER_URL =  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
                        // ? "http://localhost:8000/api/v1"
                        ?"https://server.serviarquitectura.com/api/v1"
                        :"https://server.serviarquitectura.com/api/v1"


export const API = axios.create({ baseURL: `${SERVER_URL}` })

export function updateHeaders(headers) {
    let _headersName = Object.keys(headers)
    for (let i in _headersName) {
        API.defaults.headers.common[_headersName[i]] = headers[_headersName[i]]
    }
}

