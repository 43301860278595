import React from 'react'
// import ReactDOM from 'react-dom';
// import * as Sentry from '@sentry/browser'
import { render } from 'react-snapshot'
import './index.css'
import App from './App'
// import * as serviceWorker from './serviceWorker';
// Sentry.init({ dsn: 'https://6f8bb73db90446329ff2bae41684253b@o387559.ingest.sentry.io/5223200' })

render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
