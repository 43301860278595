import ApolloClient from 'apollo-boost'
import { gql } from 'apollo-boost'
import Cookies from 'universal-cookie'
import {SERVER_URL} from '../API/main'

const cookie = new Cookies()
const baseURL =`${SERVER_URL}/graphql/`


export const PrivateMutate = (mutation) => {
    return new ApolloClient({
        uri: baseURL,
        headers: { Authorization: 'JWT ' + cookie.get('tokeng') },
    }).mutate({
        mutation: gql`
            ${mutation}
        `,
    })
}

export const PrivateQuery = (query) => {
    return new ApolloClient({
        uri: baseURL,
        headers: { Authorization: 'JWT ' + cookie.get('tokeng') },
    }).query({
        query: gql`
            ${query}
        `,
    })
}
