import { API } from './main'

export const PaginatedParticipant = (params) => API.get('/participants/' + params)
export const RetrieveParticipant = (uid) => API.get('/participants/' + uid + '/')
export const CreateParticipant = (data) => API.post('/participants/', data)
export const DeleteParticipant = (uid) => API.delete('/participants/' + uid + '/')
export const UpdateParticipant = (uid, data) => API.patch('/participants/' + uid + '/', data)
export const EstatesParticipant = (uid) => API.get('/participants/' + uid + '/estates/')

export const GetParticipantEstate = (params) => API.get('/participants/estate/' + params)
export const ParticipantEstate = (data) => API.post('/participants/estate/create/', data)
export const ParticipantEstateDelete = (data) => API.post('/participants/estate/delete/', data)

export const CreateContact = (data) => API.post('/participants/contact/', data)
export const GetContacts = (params) => API.get('/participants/contact/?' + params)
export const UpdateContact = (pk, data) => API.patch('/participants/contact/' + pk + '/', data)
export const RemoveContact = (pk) => API.delete('/participants/contact/' + pk + '/')

export const CreateContactObservation = (data) => API.post('/participants/contact/observations/', data)
export const GetContactObservations = (params) => API.get('/participants/contact/observations/?' + params)
export const UpdateContactObservation = (pk, data) => API.patch('/participants/contact/observations/' + pk + '/', data)
export const RemoveContactObservation = (pk) => API.delete('/participants/contact/observations/' + pk + '/')

export const CreateParticipantObservation = (data) => API.post('/participants/observations/', data)
export const GetParticipantObservations = (params) => API.get('/participants/observations/?' + params)
export const UpdateParticipantObservation = (pk, data) => API.patch('/participants/observations/' + pk + '/', data)
export const RemoveParticipantObservation = (pk) => API.delete('/participants/observations/' + pk + '/')

export const Roles = (params) => API.get('/participants/role/' + params)
export const CreateRoles = (data) => API.post('/participants/role/create/', data)
export const UpdateRoles = (data, pk) => API.patch('/participants/role/update/' + pk + '/', data)
export const DeleteRoles = (pk) => API.delete('/participants/role/delete/' + pk + '/')
export const ParticipantDenomination = () => API.get('/participants/denomination/')
export const ContactRoles = () => API.get('/participants/contact/role/')
export const Types = () => API.get('/participants/type/')

export const RolesContact = (params) => API.get('/participants/contact/role/' + params)
export const CreateRolesContact = (data) => API.post('/participants/contact/role/create/', data)
export const UpdateRolesContact = (data, pk) => API.patch('/participants/contact/role/update/' + pk + '/', data)
export const DeleteRolesContact = (pk) => API.delete('/participants/contact/role/delete/' + pk + '/')
