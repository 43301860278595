import React from 'react'
import styled from 'styled-components'
import FeatherIcon from 'feather-icons-react'
export default function SectionsHeader(props) {
    return (
        <div className="w-100">
            <div className="flex-content content-tab" style={{ margin: 0 }}>
                {props.location.pathname !== '/' && props.current !== '' && props.current !== null && (
                    <div className={'tab'}>
                        <GoBack
                            onClick={() => {
                                props.setCurrent()
                                props.history.goBack()
                            }}>
                            <FeatherIcon icon="chevron-left" stroke="#3da7c3" size="22px" className="icon" />
                        </GoBack>
                    </div>
                )}
                {/* // Participants */}
                {props.current === 'participant' && (
                    <>
                        {(props.invoices > 4 || props.budgets > 4 || props.contacts > 4 || props.estates > 4 || props.projects > 4) && (
                            <div className={props.currentTab === '1' ? 'current-tab tab ' : 'tab'} data-tab="1" onClick={props.changeTab}>
                                Datos generales
                            </div>
                        )}
                        {props.invoices > 4 && (
                            <div className={props.currentTab === '2' ? 'current-tab tab ' : 'tab'} data-tab="2" onClick={props.changeTab}>
                                Facturas
                            </div>
                        )}

                        {props.budgets > 4 && (
                            <div className={props.currentTab === '3' ? 'current-tab tab ' : 'tab'} data-tab="3" onClick={props.changeTab}>
                                Presupuestos
                            </div>
                        )}
                        {props.contacts > 4 && (
                            <div className={props.currentTab === '4' ? 'current-tab tab ' : 'tab'} data-tab="4" onClick={props.changeTab}>
                                Contactos
                            </div>
                        )}
                        {props.estates > 4 && (
                            <div className={props.currentTab === '5' ? 'current-tab tab ' : 'tab'} data-tab="5" onClick={props.changeTab}>
                                Fincas
                            </div>
                        )}
                        {props.projects > 4 && props.participant.projects > 0 && (
                            <div className={props.currentTab === '6' ? 'current-tab tab ' : 'tab'} data-tab="6" onClick={props.changeTab}>
                                Proyectos
                            </div>
                        )}
                    </>
                )}
                {/* // Participants */}
                {props.current === 'contact' && (
                    <>
                        {(props.invoices > 4 || props.budgets > 4 || props.estates > 4 || props.projects > 4) && (
                            <div className={props.currentTab === '1' ? 'current-tab tab ' : 'tab'} data-tab="1" onClick={props.changeTab}>
                                Datos generales
                            </div>
                        )}
                        {props.invoices > 4 && (
                            <div className={props.currentTab === '2' ? 'current-tab tab ' : 'tab'} data-tab="2" onClick={props.changeTab}>
                                Facturas
                            </div>
                        )}

                        {props.budgets > 4 && (
                            <div className={props.currentTab === '3' ? 'current-tab tab ' : 'tab'} data-tab="3" onClick={props.changeTab}>
                                Presupuestos
                            </div>
                        )}

                        {props.estates > 4 && (
                            <div className={props.currentTab === '5' ? 'current-tab tab ' : 'tab'} data-tab="5" onClick={props.changeTab}>
                                Fincas
                            </div>
                        )}
                        {props.projects > 4 && props.participant.projects > 0 && (
                            <div className={props.currentTab === '6' ? 'current-tab tab ' : 'tab'} data-tab="6" onClick={props.changeTab}>
                                Proyectos
                            </div>
                        )}
                    </>
                )}
                {/* // invoices */}
                {props.current === 'invoice' && (
                    <>
                        <div className={props.currentTab === '1' ? 'current-tab tab ' : ' tab'} data-tab="1" onClick={props.changeTab}>
                            Datos generales
                        </div>
                        {/* <div className={props.currentTab === '2' ? 'current-tab tab ' : ' tab'} data-tab="2" onClick={props.changeTab}>
                    Observaciones
                </div> */}
                        {props.state === 7 && (
                            <div className={props.currentTab === '4' ? 'current-tab tab ' : 'tab'} data-tab="4" onClick={props.changeTab}>
                                Abonos
                            </div>
                        )}
                    </>
                )}
                {/* // Estates */}
                {props.current === 'estate' && (
                    <>
                        {(props.invoices > 4 || props.budgets > 4 || props.projects > 4 || props.participants > 4 || props.departments > 4) && (
                            <div className={props.currentTab === '1' ? 'current-tab tab ' : 'tab'} data-tab="1" onClick={props.changeTab}>
                                Datos generales
                            </div>
                        )}
                        {/* {props.invoices > 4 && (
                            <div className={props.currentTab === '2' ? 'current-tab tab ' : 'tab'} data-tab="2" onClick={props.changeTab}>
                                Facturas
                            </div>
                        )}
                        {props.budgets > 4 && (
                            <div className={props.currentTab === '3' ? 'current-tab tab ' : 'tab'} data-tab="3" onClick={props.changeTab}>
                                Presupuestos
                            </div>
                        )} */}
                        {props.participants > 4 && (
                            <div className={props.currentTab === '4' ? 'current-tab tab ' : 'tab'} data-tab="4" onClick={props.changeTab}>
                                Promotores
                            </div>
                        )}
                        {typeof props.departments !== 'undefined' && props.departments > 4 && (
                            <div className={props.currentTab === '5' ? 'current-tab tab ' : 'tab'} data-tab="5" onClick={props.changeTab}>
                                Departamentos
                            </div>
                        )}
                        {typeof props.projects !== 'undefined' && props.projects > 4 && (
                            <div className={props.currentTab === '6' ? 'current-tab tab ' : 'tab'} data-tab="6" onClick={props.changeTab}>
                                Proyectos
                            </div>
                        )}
                    </>
                )}
                {/* // Budgets */}
                {props.current === 'budget' && (
                    <>
                        <div className={props.currentTab === '1' ? 'current-tab tab' : 'tab'} data-tab="1" onClick={props.changeTab}>
                            Datos generales
                        </div>
                        {/* <div className={props.currentTab === '2' ? 'current-tab tab' : 'tab'} data-tab="2" onClick={props.changeTab}>
                    Obsevaciones
                </div> */}

                        {props.invoices > 4 && (
                            <div className={props.currentTab === '2' ? 'current-tab tab ' : 'tab'} data-tab="2" onClick={props.changeTab}>
                                Facturas
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

const GoBack = styled.a`
    font-size: 15px !important;
    /* padding: 15px 20px !important; */
    /* border-right: solid 1px #3da7c3; */
    text-align: center;
    margin-top: 0 !important;
`
