import React from 'react'
import styled from 'styled-components'
import InputMask from 'react-input-mask'
import FeatherIcon from 'feather-icons-react'

export default function ContactParticipantInfo(props) {
    return (
        <div>
            <Content>
                <h4 className="title-body-modal">{props.state.title === 'Comunidad' ? `Datos de contacto de la ${props.state.title}` : `Datos de contacto del ${props.state.title}`}</h4>
                <div className="row">
                    <div className="col-12 py-1">
                        <small className="field-name">Correo electrónico</small>
                    </div>
                    <div className="col-6">
                        <input autoComplete="off" onChange={props.handleChangeField} value={props.fields.email} id="email" type="email" className="form-control" />
                    </div>
                    <div className="col-5">
                        <select onChange={props.handleChangeField} value={props.fields.emailType} id="emailType" className="form-control">
                            <option value="1">Profesional</option>
                            <option value="2">Particular</option>
                            <option value="3">Otro</option>
                        </select>
                    </div>
                    <div className="col-1 text-center" style={{ paddingTop: '12px' }}>
                        <AddMore onClick={props.addEmail}>
                            <FeatherIcon icon="plus-circle" size="16px" />
                        </AddMore>
                    </div>
                    {props.state.emails.map((e, key) => (
                        <div key={`email-${key}`} className="w-100 d-flex">
                            <div className="col-6">
                                <input
                                    autoComplete="off"
                                    placeholder={`Correo #${key + 1}`}
                                    data-index={key}
                                    onChange={props.handleChangeEmail}
                                    value={e.email}
                                    id="email"
                                    type="email"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-5">
                                <select onChange={props.handleChangeEmail} value={e.type} id="type" data-index={key} className="form-control">
                                    <option value="1">Profesional</option>
                                    <option value="2">Particular</option>
                                    <option value="3">Otro</option>
                                </select>
                            </div>
                            <div className="col-1 text-center" style={{ paddingTop: '15px' }}>
                                <span onClick={props.removeEmail} data-key={key}>
                                    <FeatherIcon icon="trash" className="icon" size="16px" />
                                </span>
                            </div>
                        </div>
                    ))}
                    {!props.fields.type.includes(5) && (
                        <>
                            <div className="col-12 py-1">
                                <small className="field-name">Teléfono</small>
                            </div>
                            <div className="col-6">
                                <InputMask
                                    mask="99 999 99 99"
                                    autoComplete="off"
                                    onChange={props.handleChangeField}
                                    value={props.fields.phoneNumber}
                                    id="phoneNumber"
                                    type="tel"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-5">
                                <select onChange={props.handleChangeField} value={props.fields.phoneType} id="emailType" className="form-control">
                                    <option value="1">Profesional</option>
                                    <option value="2">Particular</option>
                                    <option value="3">Otro</option>
                                </select>
                            </div>
                            <div className="col-1 text-center" style={{ paddingTop: '12px' }}>
                                <AddMore onClick={props.addPhone}>
                                    <FeatherIcon icon="plus-circle" size="16px" />
                                </AddMore>
                            </div>
                            {props.state.phones.map((e, key) => (
                                <div key={`phone-${key}`} className="w-100 d-flex">
                                    <div className="col-6">
                                        <InputMask
                                            data-index={key}
                                            mask="99 999 99 99"
                                            autoComplete="off"
                                            onChange={props.handleChangePhone}
                                            value={e.phone}
                                            id="phone"
                                            type="tel"
                                            placeholder={`Teléfono #${key + 1}`}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-5">
                                        <select onChange={props.handleChangePhone} value={e.type} id="type" data-index={key} className="form-control">
                                            <option value="1">Profesional</option>
                                            <option value="2">Particular</option>
                                            <option value="3">Otro</option>
                                        </select>
                                    </div>
                                    <div className="col-1 text-center" style={{ paddingTop: '15px' }}>
                                        <span onClick={props.removePhone} data-key={key}>
                                            <FeatherIcon icon="trash" className="icon" size="16px" />
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                    <div className="col-12">
                        <label className="label-form">
                            <small className="field-name">Página web</small>
                            <input autoComplete="off" onChange={props.handleChangeField} value={props.fields.website} id="website" type="text" className="form-control" />
                        </label>
                    </div>
                    <div className="col-12">
                        <label className="label-form">
                            <small className="field-name">Instagram</small>
                            <input autoComplete="off" onChange={props.handleChangeField} value={props.fields.instagram} id="instagram" type="text" className="form-control" />
                        </label>
                    </div>
                    <div className="col-12">
                        <label className="label-form">
                            <small className="field-name">Facebook</small>
                            <input autoComplete="off" onChange={props.handleChangeField} value={props.fields.facebook} id="facebook" type="text" className="form-control" />
                        </label>
                    </div>
                    <div className="col-12">
                        <label className="label-form">
                            <small className="field-name">Twitter</small>
                            <input autoComplete="off" onChange={props.handleChangeField} value={props.fields.twitter} id="twitter" type="text" className="form-control" />
                        </label>
                    </div>
                    <div className="col-12">
                        <label className="label-form">
                            <small className="field-name">Youtube</small>
                            <input autoComplete="off" onChange={props.handleChangeField} value={props.fields.youtube} id="youtube" type="text" className="form-control" />
                        </label>
                    </div>
                </div>
            </Content>
            <div className="row">
                <div className="col-12 text-right">
                    <button className="cancel-button" data-step="ParticipantInfo" onClick={props.setStep}>
                        Atrás
                    </button>
                    <button className="create-button" data-step="Address" onClick={props.setStep}>
                        Siguiente
                    </button>
                </div>
            </div>
        </div>
    )
}

const Content = styled.div`
    min-height: 400px;
    margin-bottom: 15px;
`
const AddMore = styled.span`
    color: #267dc0 !important;
    cursor: pointer;
    transition: all 0.3s;
    :hover {
        opacity: 0.8;
    }
`
