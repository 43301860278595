import React, { Component } from 'react'
import Logo from '../static/images/logo-white.png'
import Logo2 from '../static/images/logo-simple.png'
import escritorio from '../static/images/icon/dashboard-white.png'
import mail from '../static/images/icon/mail-white.png'
import fincas from '../static/images/icon/estate-white.png'
import proyectos from '../static/images/icon/projects-white.png'
import juridicoNegativo from '../static/images/icon/icon-administrador-white.png'
import presupuestos from '../static/images/icon/budget-white.png'
import facturas from '../static/images/icon/invoice-white.png'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import FeatherIcon from 'feather-icons-react'
import styled from 'styled-components'

const getState = (state) => {
    return {
        permissions: state.permissions,
        collapse: state.collapse,
    }
}

const Dispatch = (dispatch) => {
    return {
        Collapse: (data) => {
            const action = {
                type: 'Collapse',
                data: data,
            }
            dispatch(action)
        },
        setCurrent: () => {
            const action = {
                type: 'SetCurrent',
                tab: '',
                current: null,
            }
            dispatch(action)
        },
    }
}
class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active: 1,
            subMenu: false,
            collapse: false,
        }
    }
    _handleActive = (e) => {
        let active = parseInt(e.target.getAttribute('data-index'))
        this.setState({ active })
    }

    showSubMenu = (value) => {
        if (typeof value !== 'undefined') {
            this.setState({ subMenu: value })
        } else {
            this.setState({ subMenu: !this.state.subMenu })
        }
    }

    setCollapse = () => {
        this.setState({ collapse: !this.state.collapse }, () => {
            if (this.state.collapse === true) {
                document.getElementById('principal').classList.add('container-collapse')
            } else {
                document.getElementById('principal').classList.remove('container-collapse')
            }
        })
    }

    render() {
        return (
            <div id="nav-mobile" className={`sidebar ${this.state.collapse && 'sidebar-collapse'}`}>
                <BtnCollapse collapse={this.state.collapse} onClick={this.setCollapse}>
                    <FeatherIcon icon={this.state.collapse ? 'chevron-right' : 'chevron-left'} size={'14px'} stroke="#fff" />
                </BtnCollapse>
                <div className="logo">
                    <img src={this.state.collapse ? Logo2 : Logo} />
                </div>
                <ul>
                    <li
                        data-index="1"
                        onClick={(e) => {
                            this.props.setCurrent()
                            this._handleActive(e)
                        }}
                        className={this.state.active === 1 ? 'list-menu text-center active' : 'list-menu text-center'}>
                        <Link
                            data-index="1"
                            onClick={(e) => {
                                this.props.setCurrent()
                                this._handleActive(e)
                                this.showSubMenu(false)
                            }}
                            to="/"
                            className="waves-effect d-block">
                            <p data-index="1">
                                <img className="pointer-ignore" src={escritorio} />{' '}
                            </p>
                            <p data-index="1" className="text-link">
                                Escritorio
                            </p>
                        </Link>
                    </li>
                    {/* <li
                        data-index="2"
                        onClick={(e) => {
                            this.props.setCurrent()
                            this._handleActive(e)
                            this.showSubMenu(false)
                        }}
                        className={this.state.active === 2 ? 'list-menu text-center active' : 'list-menu text-center'}>
                        <Link
                            data-index="2"
                            onClick={(e) => {
                                this.props.setCurrent()
                                this._handleActive(e)
                                this.showSubMenu(false)
                            }}
                            to="/mail"
                            className="waves-effect d-block">
                            <p data-index="2">
                                <img className="pointer-ignore" src={mail} />{' '}
                            </p>
                            <p data-index="2" className="text-link">
                                Correos
                            </p>
                        </Link>
                    </li> */}
                    {typeof this.props.permissions !== 'undefined' && this.props.permissions.accessEstate && (
                        <li
                            data-index="3"
                            onClick={(e) => {
                                this.props.setCurrent()
                                this._handleActive(e)
                                this.showSubMenu(false)
                            }}
                            className={this.state.active === 3 ? 'list-menu text-center active' : 'list-menu text-center'}>
                            <Link
                                data-index="3"
                                onClick={(e) => {
                                    this.props.setCurrent()
                                    this._handleActive(e)
                                    this.showSubMenu(false)
                                }}
                                to="/fincas"
                                className="waves-effect d-block">
                                <p data-index="3">
                                    <img className="pointer-ignore" src={fincas} />{' '}
                                </p>
                                <p data-index="3" className="text-link">
                                    Fincas
                                </p>
                            </Link>
                        </li>
                    )}
                    {/* {typeof this.props.permissions !== 'undefined' && this.props.permissions.accessProject && (
                        <li
                            data-index="4"
                            onClick={(e) => {
                                this.props.setCurrent()
                                this._handleActive(e)
                                this.showSubMenu(false)
                            }}
                            className={this.state.active === 4 ? 'list-menu text-center active' : 'list-menu text-center'}>
                            <Link
                                data-index="4"
                                onClick={(e) => {
                                    this.props.setCurrent()
                                    this._handleActive(e)
                                    this.showSubMenu(false)
                                }}
                                to="/proyectos"
                                className="waves-effect d-block">
                                <p data-index="4">
                                    <img className="pointer-ignore" src={proyectos} />{' '}
                                </p>
                                <p data-index="4" className="text-link">
                                    Proyectos
                                </p>
                            </Link>
                        </li>
                    )} */}
                    {typeof this.props.permissions !== 'undefined' && this.props.permissions.accessParticipant && (
                        <li
                            data-index="5"
                            className="list-menu text-center"
                            onClick={(e) => {
                                this.props.setCurrent()
                                this._handleActive(e)
                            }}>
                            <Link
                                data-index="5"
                                onClick={(e) => {
                                    this.props.setCurrent()
                                    this._handleActive(e)
                                }}
                                to="/participantes"
                                className="waves-effect d-block">
                                <p data-index="5">
                                    <img className="pointer-ignore" src={juridicoNegativo} />{' '}
                                </p>
                                <p data-index="5" className="text-link">
                                    Intervinientes
                                </p>
                            </Link>
                        </li>
                    )}
                    {typeof this.props.permissions !== 'undefined' && this.props.permissions.accessBudget && (
                        <li
                            data-index="7"
                            onClick={(e) => {
                                this.props.setCurrent()
                                this._handleActive(e)
                                this.showSubMenu(false)
                            }}
                            className={this.state.active === 7 ? 'list-menu text-center active' : 'list-menu text-center'}>
                            <Link
                                data-index="7"
                                onClick={(e) => {
                                    this.props.setCurrent()
                                    this._handleActive(e)
                                    this.showSubMenu(false)
                                }}
                                to="/presupuestos"
                                className="waves-effect d-block">
                                <p data-index="7">
                                    <img className="pointer-ignore" src={presupuestos} />
                                </p>
                                <p data-index="7" className="text-link">
                                    Presupuestos
                                </p>
                            </Link>
                        </li>
                    )}
                    {typeof this.props.permissions !== 'undefined' && this.props.permissions.accessInvoice && (
                        <li
                            data-index="8"
                            onClick={(e) => {
                                this.props.setCurrent()
                                this._handleActive(e)
                                this.showSubMenu(false)
                            }}
                            className={this.state.active === 8 ? 'list-menu text-center active' : 'list-menu text-center'}>
                            <Link
                                data-index="8"
                                onClick={(e) => {
                                    this.props.setCurrent()
                                    this._handleActive(e)
                                    this.showSubMenu(false)
                                }}
                                to="/facturas"
                                className="waves-effect d-block">
                                <p data-index="8">
                                    <img className="pointer-ignore" src={facturas} />
                                </p>
                                <p data-index="8" className="text-link">
                                    Facturas
                                </p>
                            </Link>
                        </li>
                    )}
                    {typeof this.props.permissions !== 'undefined' && this.props.permissions.accessConfig && (
                        <li
                            data-index="9"
                            onClick={(e) => {
                                this.props.setCurrent()
                                this._handleActive(e)
                                this.showSubMenu(false)
                            }}
                            className={this.state.active === 9 ? 'list-menu text-center active' : 'list-menu text-center'}>
                            <Link
                                data-index="9"
                                onClick={(e) => {
                                    this.props.setCurrent()
                                    this._handleActive(e)
                                    this.showSubMenu(false)
                                }}
                                to="/parametrizacion"
                                className="waves-effect d-block">
                                <p data-index="9" style={{ fontSize: '19px' }}>
                                    <i className="icon fas fa-cog" />
                                </p>
                                <p data-index="9" className="text-link">
                                    Parametrización
                                </p>
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
        )
    }
}

export default connect(getState, Dispatch)(Sidebar)

const BtnCollapse = styled.a`
    background-color: #3da7c3;
    padding: 2px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    transition: all 0.2s ease;
    position: fixed;
    margin-left: ${(props) => (props.collapse ? '15px' : '60px')};
    float: right;
    z-index: 9999999;
    width: 20px;
    height: 20px;
    top: 5px;
`
