import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { NewMedia } from '../../API/files'
import Cookies from 'universal-cookie'
import { Spinner } from '@blueprintjs/core'
const cookies = new Cookies()
const token = cookies.get('token')

export default function Card(props) {
    const [loading, setLoading] = React.useState(false)
    const handleChange = async (e) => {
        setLoading(true)
        let files = e.target.files
        let response = null
        let uploaded_files = []
        let data = new FormData()
        for (let x = 0; x < files.length; x++) {
            data.append('file', files[x])
            data.append('name', files[x].name)
            response = await NewMedia(data, token)
            uploaded_files.push(response.data.uid)
        }
        props.onChange(uploaded_files)
        setLoading(false)
    }

    return (
        <div className="form-control">
            <input disabled={loading} type="file" multiple id="files" style={{ width: '90%' }} onChange={handleChange} />
            {loading && (
                <div style={{ width: '10%', textAlign: 'right' }} className="d-inline">
                    <Spinner size="14" />
                </div>
            )}
        </div>
    )
}
