import React from 'react'
import styled from 'styled-components'
import FeatherIcon from 'feather-icons-react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { UpdateNotification } from '../../API/notifications'

export default function NotificationCard(props) {
    let history = useHistory()

    function GetColor(type) {
        switch (type) {
            case 'accept':
                return '#1AB258'
            case 'reject':
                return '#F6A92E'
            case 'error':
                return '#E03F4D'
            case 'system':
                return '#27ABD3'
        }
    }

    function getDiffDate(date) {
        var today = moment()
        if (today.diff(date, 'days') === 1) return `Hace ${today.diff(date, 'days')} dia.`
        if (today.diff(date, 'days') > 1) return `Hace ${today.diff(date, 'days')} dias.`
        if (today.diff(date, 'hours') === 1) return `Hace ${today.diff(date, 'hours')} Hora.`
        if (today.diff(date, 'hours') > 1) return `Hace ${today.diff(date, 'hours')} Horas.`
        if (today.diff(date, 'minutes') === 1) return `Hace ${today.diff(date, 'minutes')} minuto.`
        if (today.diff(date, 'minutes') > 1) return `Hace ${today.diff(date, 'minutes')} minutos.`
        if (today.diff(date, 'seconds') === 1) return `Hace ${today.diff(date, 'seconds')} segundo.`
        if (today.diff(date, 'seconds') > 1) return `Hace ${today.diff(date, 'seconds')} segundos.`
    }

    const handleRedirect = async () => {
        let nt = props.notification
        if (nt.read == false) props.setCountUnread(props.count_unread - 1)
        switch (nt.object_type) {
            case 'budget':
                await UpdateNotification(nt.uid, { read: true })
                history.push(`/presupuestos/ver/${nt.related.id}`)
                break

            default:
                break
        }
    }
    return (
        <NotificationCard_ read={props.notification.read} onClick={handleRedirect} bgColor={GetColor(props.notification.type)}>
            <table>
                <tr>
                    <td>
                        <BadgeIcon bgColor={GetColor(props.notification.type)}>
                            {props.notification.type === 'accept' && <FeatherIcon icon="check" size="18" stroke="#fff" />}
                            {props.notification.type === 'reject' && <FeatherIcon icon="slash" size="18" stroke="#fff" />}
                            {props.notification.type === 'error' && <FeatherIcon icon="x" size="18" stroke="#fff" />}
                            {props.notification.type === 'system' && <FeatherIcon icon="info" size="18" stroke="#fff" />}
                        </BadgeIcon>
                    </td>
                    <td>
                        <p>
                            <strong>{props.notification.title}</strong>
                            <br />
                            <small>{props.notification.description}</small>
                        </p>
                        <Time>{getDiffDate(props.notification.timestamp)}</Time>
                    </td>
                </tr>
            </table>
        </NotificationCard_>
    )
}

const NotificationCard_ = styled.div`
    width: 300px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: ${(props) => (props.read ? '#fff' : '#fffbef')};
    :hover {
        background-color: #efefef;
    }
    border-bottom: solid 1px #eee;
    position: relative;
    :before {
        content: '';
        position: absolute;
        background-color: ${(props) => (props.bgColor ? props.bgColor : '#3f75e0')};
        width: 2px;
        height: 100%;
    }

    td {
        padding: 10px 5px;
        vertical-align: middle;
        p {
            margin: 0;
        }
    }
    table {
        pointer-events: none;
    }
`

const BadgeIcon = styled.div`
    text-align: center;
    padding-top: 6px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${(props) => (props.bgColor ? props.bgColor : '#3f75e0')};
    margin-left: 5px;
`

const Time = styled.p`
    color: #777;
    font-size: 10px;
    text-align: right;
`
