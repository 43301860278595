import React from 'react'
import AsyncSelect from 'react-select/lib/Async'
import { PaginatedParticipant } from '../../API/Participants'

const customStyles = {
    dropdownIndicator: (provided, state) => ({
        display: 'none',
    }),
    loadingIndicator: (provided, state) => ({
        display: 'none',
    }),
    indicatorSeparator: (provided, state) => ({
        display: 'none',
    }),
}
export default function InputParticipant(props) {
    const [debounce, setDebounce] = React.useState(null)
    const loadData = (inputValue, callback, id, addNew) => {
        let debounce_ = debounce
        let action = ''
        if (id === 'all') action = PaginatedParticipant(`?limit=15&search=${inputValue}`)
        if (id === 'administrator') action = PaginatedParticipant(`?limit=15&type=1&search=${inputValue}`)
        if (id === 'referer') action = PaginatedParticipant(`?limit=15&type=4&search=${inputValue}`)
        if (id === 'professional') action = PaginatedParticipant(`?limit=15&type=3&search=${inputValue}`)
        clearTimeout(debounce_)
        debounce_ = setTimeout(() => {
            return action
                .then(function (response) {
                    let data = []
                    let results = response.data.results
                    for (let x = 0; x < results.length; x++) {
                        data.push({ label: results[x].readName, value: results[x].uid, discount: results[x].discount, addressInvoice: results[x].addressInvoice })
                    }
                    if (addNew) {
                        {
                            id === 'all' &&
                                data.push({
                                    label: (
                                        <div value={inputValue} type="0" style={{ color: '#777', height: '100%', width: '100%' }}>
                                            <i className="icon fas fa-user mx-2"></i> Nuevo promotor{' '}
                                        </div>
                                    ),
                                    value: -1,
                                })
                        }
                        {
                            id === 'all' &&
                                data.push({
                                    label: (
                                        <div value={inputValue} type="1" style={{ color: '#777', height: '100%', width: '100%' }}>
                                            <i className="icon fas fa-building mx-2"></i> Nueva comunidad de propietarios{' '}
                                        </div>
                                    ),
                                    value: -2,
                                })
                        }
                        {
                            ;(id === 'all' || id === 'administrator') &&
                                data.push({
                                    label: (
                                        <div value={inputValue} type="1" style={{ color: '#777', height: '100%', width: '100%' }}>
                                            <i className="icon fas fa-building mx-2"></i> Nuevo administrador{' '}
                                        </div>
                                    ),
                                    value: -3,
                                })
                        }
                        {
                            ;(id === 'all' || id === 'referer') &&
                                data.push({
                                    label: (
                                        <div value={inputValue} type="4" style={{ color: '#777', height: '100%', width: '100%' }}>
                                            <i className="icon fas fa-building mx-2"></i> Nuevo referidor{' '}
                                        </div>
                                    ),
                                    value: -4,
                                })
                        }
                        {
                            ;(id === 'all' || id === 'professional') &&
                                data.push({
                                    label: (
                                        <div value={inputValue} type="3" style={{ color: '#777', height: '100%', width: '100%' }}>
                                            <i className="icon fas fa-building mx-2"></i> Nuevo profesional{' '}
                                        </div>
                                    ),
                                    value: -5,
                                })
                        }
                    }
                    return callback(data)
                })
                .catch(function (error) {
                    return callback(null)
                })
        }, 1000)
        setDebounce(debounce_)
    }

    return (
        <AsyncSelect
            isMulti={props.isMulti}
            classNamePrefix="react-select"
            placeholder=""
            noOptionsMessage={() => null}
            isClearable
            styles={customStyles}
            value={props.value}
            isDisabled={props.disabled}
            loadOptions={(inputValue, callback) => loadData(inputValue, callback, props.type, props.creatable)}
            onChange={props.onChange}
        />
    )
}
