import React, { Component } from 'react'
import Modal from 'react-responsive-modal'
import { Switch, Intent, Position, Toaster } from '@blueprintjs/core'
import Select from 'react-select'
import axios from 'axios'
import ParticipantInfo from '../../Components/Participants/FieldsForm/ParticipantInfo'
import ContactParticipantInfo from '../../Components/Participants/FieldsForm/ContactParticipantInfo'
import HeaderForm from '../../Components/Participants/HeaderForm'
import { connect } from 'react-redux'
import AsyncSelect from 'react-select/lib/Async'
import CreatableSelect from 'react-select/lib/Creatable'
import {
    Roles,
    Types,
    ContactRoles,
    CreateParticipant,
    PaginatedParticipant,
    CreateContact,
    UpdateContact,
    RemoveContact,
    GetContacts,
    ParticipantEstate,
    ParticipantDenomination,
    UpdateParticipant,
} from '../../API/Participants'
import { PaginatedAddress, CreateNumber, CreateAddress, GetNumber, PaginatedProvince, PaginatedMunicipality, PaginatedVia } from '../../API/Address'
import { CreateEstate } from '../../API/Estates'
import IconLoading from '../../static/images/icon/spinner-icon.gif'
import InputMask from 'react-input-mask'
import { PrivateQuery } from '../../API/Apollo'
import Cookies from 'universal-cookie'

const AppToaster = Toaster.create({
    intent: Intent.WARNING,
    className: 'recipe-toaster',
    position: Position.TOP,
    icon: 'warning-sign',
})
const cookies = new Cookies()

const customStyles = {
    dropdownIndicator: (provided, state) => ({
        display: 'none',
    }),
    loadingIndicator: (provided, state) => ({
        display: 'none',
    }),
    indicatorSeparator: (provided, state) => ({
        display: 'none',
    }),
}

const fields_address = []
const fields_fields = ['type', 'fullName']
const fields_contacts = []
const defaultFields = { fullName: '', commercialName: '', observation: '', phoneNumber: '', CIF: '', role: '', email: '', referer: '', discount: 0, administrator: '', manager: [] }
const defaultAddress = { via: '', municipality: '', province: '', zipCode: '', floor: '', chairs: '', number: '', door: '' }

const getState = (state) => {
    return {
        changeListResult: state.changeListResult,
    }
}
const Dispatch = (dispatch) => {
    return {
        changeList: (e) => {
            const action = {
                value: 1,
                type: 'changeList',
            }
            dispatch(action)
        },
    }
}

class ModalContact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            estateIsForProject: false,
            info_form: true,
            loading: false,
            equalAddress: false,
            debounce: '',
            advanced_profile: false,
            advanced_address: false,
            address_form: true,
            contact_form: true,
            title: 'Interviniente',
            emails: [],
            phones: [],
            list_contacts: [],
            legalForms: [],
            numbers: [],
            provinces: [],
            vias: [],
            municipalities: [],
            moreOptions: 'ParticipantInfo',
            type: [],
            role: [],
            is_autonomo: false,
            contactRoles: [],
            address: {
                via: '',
                municipality: '',
                province: '',
                zipCode: '',
                floor: '',
                chairs: '',
                number: '',
                door: '',
            },
            addressText: {
                via: '',
                municipality: '',
                province: '',
                number: '',
                zipCode: '',
            },
            fields: {
                type: '', //Tipo de participante
                type2: '0', //Tipo Fisico/Juridico
                fullName: '',
                legalForm: '',
                commercialName: '',
                observation: '',
                phoneNumber: '',
                CIF: '',
                isCompany: false,
                role: '',
                email: '',
                referer: '',
                discount: 0,
                comisionType: '1',
                administrator: '',
                manager: [],
            },
            contacts: [],
            referers: [],
            administrators: [],
            grph: null,
        }
    }

    cleanFields = () => {
        let address = {
            via: '',
            municipality: '',
            province: '',
            zipCode: '',
            floor: '',
            chairs: '',
            number: '',
            door: '',
        }

        let fields = this.state.fields
        fields.fullName = ''
        fields.legalForm = ''
        fields.commercialName = ''
        fields.observation = ''
        fields.phoneNumber = ''
        fields.CIF = ''
        fields.isCompany = false
        fields.role = ''
        fields.email = ''
        fields.referer = ''
        fields.discount = 0
        fields.administrator = ''
        fields.manager = []

        let contacts = []
        if (this.props.refer === 1 || this.props.refer === 3) {
            contacts.push({ fullName: '', phoneNumber: '', email: '', CIF: '', role: 8, isRepresentative: true })
        }

        this.setState({ fields, address, contacts, is_autonomo: false })
    }

    componentWillMount = () => {
        let global = this
        let title = ''
        if (this.props.edit) {
            let query = `{participant(id: ${this.props.responsible}) { id type2 fullName commercialName legalForm email CIF phoneNumber comision discount observation manager {id fullName} address { id province { id name } municipality { id name } via { id name } number { id value } zipCode chairs floor door } type { id } administrator { id fullName } referer { id fullName } contactSet { id fullName phoneNumber isRepresentative email CIF role { id name } } } }`

            PrivateQuery(query, cookies.get('tokeng')).then((response) => {
                let participant = response.data.participant
                let contacts = participant.contactSet
                for (let x = 0; x < contacts.length; x++) {
                    contacts[x].role = contacts[x].role.id
                }
                let fields = this.state.fields
                let address = { ...participant.address }
                address.province = address.province.id
                address.municipality = address.municipality.id
                address.via = address.via.id
                address.number = address.number.id
                fields = { ...participant }
                // fields.type = [2]
                fields.address = fields.address.id
                GetNumber('?via=' + address.via).then(function (response) {
                    let numbers = []
                    let results = response.data
                    for (let x = 0; x < results.length; x++) {
                        numbers.push({ label: results[x].value, value: results[x].id })
                    }
                    global.setState({ numbers })
                })
                this.setState({ fields, contacts, address, grph: participant, title: 'Promotor' })
            })
        } else {
            let fields = this.state.fields
            fields.type = this.props.refer === undefined || this.props.refer === -1 ? [0] : [this.props.refer]
            let equalAddress = false
            let estateIsForProject = false
            switch (this.props.refer) {
                case 1:
                    title = 'Administrador'
                    break
                case 2:
                    title = 'Promotor'
                    break
                case 3:
                    title = 'Profesional'
                    break
                case 4:
                    title = 'Referidor'
                    break
                case 5:
                    title = 'Comunidad'
                    equalAddress = true
                    estateIsForProject = true
                    break
                default:
                    title = 'Interviniente'
                    break
            }
            fields.type2 = '1'

            if (this.props.type2Prop) {
                fields.type2 = this.props.type2Prop
                fields.fullName = this.props.name_participant_props
            }
            this.setState({ title, fields, equalAddress, estateIsForProject })
        }
        axios.all([Roles(''), Types(), ContactRoles(''), ParticipantDenomination()]).then(
            axios.spread(function (roles, types, contactRoles, legalForm) {
                let aux = []
                let aux1 = []
                let aux2 = []
                for (let x = 0; x < types.data.length; x++) {
                    if (types.data[x].id !== global.props.refer && types.data[x].id !== 5) aux.push({ label: types.data[x].name, value: types.data[x].id })
                }
                for (let x = 0; x < roles.data.length; x++) {
                    aux1.push({ label: roles.data[x].name, value: roles.data[x].id })
                }
                for (let x = 0; x < contactRoles.data.length; x++) {
                    aux2.push({ label: contactRoles.data[x].name, value: contactRoles.data[x].id })
                }
                if (global.props.refer === 1 || global.props.refer === 3) {
                    let contacts = []
                    contacts.push({ fullName: '', phoneNumber: '', email: '', CIF: '', role: 8, isRepresentative: true })
                    global.setState({ contacts })
                }
                if (legalForm.data.length > 0) {
                    let fields = Object.assign({}, global.state.fields)
                    fields.legalForm = global.props.refer === 5 ? legalForm.data[0].id : null
                    global.setState({ fields })
                }
                global.setState({ type: aux, role: aux1, contactRoles: aux2, legalForms: legalForm.data })
            })
        )
    }

    _handleChangeField = (e) => {
        let id = e.target.getAttribute('id')
        let val = e.target.value
        let fields = this.state.fields
        fields[id] = val
        this.setState({ fields })
    }

    _handleChangeContactField = (e) => {
        let id = e.target.getAttribute('id')
        let index = e.target.getAttribute('data-index')
        let val = e.target.value
        let contacts = this.state.contacts
        contacts[index][id] = val
        this.setState({ contacts })
    }

    _changeContactRole = (e, index) => {
        let val = e.value
        let contacts = this.state.contacts
        contacts[index]['role'] = val
        this.setState({ contacts })
    }

    _addContact = () => {
        let contacts = this.state.contacts
        contacts.push({ fullName: '', phoneNumber: '', email: '', CIF: '', role: '', isRepresentative: false })
        this.setState({ contacts })
    }

    _removeContact = (e) => {
        let contacts = this.state.contacts
        let index = e.target.getAttribute('data-index')
        if (contacts[index].id) {
            RemoveContact(contacts[index].id)
        }
        contacts.splice(index, 1)

        this.setState({ contacts })
    }

    _handleChangeAddress = (e) => {
        let id = e.target.getAttribute('id')
        let val = e.target.value
        let address = this.state.address
        address[id] = val
        this.setState({ address })
    }

    validateStateContacts = (index, fields) => {
        let state = this.state
        let is_valid = true
        for (let i = 0; i < state.contacts.length; i++) {
            for (let x in fields) {
                let elemento = document.getElementById(fields[x])
                if (state.contacts[i][fields[x]] === '') {
                    if (elemento !== null) elemento.classList.add('invalid')
                    is_valid = false
                } else {
                    if (elemento !== null) elemento.classList.remove('invalid')
                }
            }
        }
        return is_valid
    }

    validateState = (index, fields) => {
        let state = this.state
        let is_valid = true
        for (let x in fields) {
            let elemento = document.getElementById(fields[x])
            if (state[index][fields[x]] === '') {
                if (elemento !== null) elemento.classList.add('invalid')
                is_valid = false
            } else {
                if (elemento !== null) elemento.classList.remove('invalid')
            }
        }
        return is_valid
    }

    ProcessDataParticipant = async (data, title, contacts, address) => {
        data.phones = this.state.phones
        data.emails = this.state.emails
        let instance = this.state.grph !== null ? UpdateParticipant(data.id, data) : CreateParticipant(data) //Instancia para crear o editar segun sea el caso
        try {
            let UpdateCreateParticipant = await instance
            //Si es crear la instancia, entonces se procede a crear la finca tambien y asociarla al promotor(Nota si existe la finca solo devuelte el dict pero no se duplica validado en backend)
            if (this.state.grph === null) {
                if (address !== null && this.state.estateIsForProject && (UpdateCreateParticipant.data.type.includes('Promotor') || UpdateCreateParticipant.data.type.includes('Comunidad'))) {
                    let estate = await CreateEstate({ address })
                    let data_ = {
                        estate: estate.data.id,
                        participant: UpdateCreateParticipant.data.uid,
                    }
                    await ParticipantEstate(data_)
                }
            }
            //Crea los contactos
            for (let x = 0; x < contacts.length; x++) {
                contacts[x].parent = UpdateCreateParticipant.data.uid
                if (contacts[x].id) {
                    await UpdateContact(contacts[x].id, contacts[x])
                } else {
                    await CreateContact(contacts[x])
                }
            }
            //Inicializa todos los valores a los valores por defecto
            setTimeout(() => {
                this.setState({ loading: false })
                if (typeof this.props.type2Prop !== 'undefined' || this.props.returnData) {
                    this.props.onClose(UpdateCreateParticipant.data, this.props.refer)
                } else {
                    this.props.onClose()
                }
                this.setState({ loading: false, fields: defaultFields, address: defaultAddress, contacts: [] })
                if (this.state.grph === null) {
                    AppToaster.show({ intent: Intent.SUCCESS, message: title + ' creado exitosamente!' })
                } else {
                    AppToaster.show({ intent: Intent.SUCCESS, message: title + ' actualizado exitosamente!' })
                }
                this.props.changeList()
            }, 500)
        } catch (err) {
            AppToaster.show({ intent: Intent.DANGER, message: 'Ocurrio un error al intentar crear el interviniente.' })
            this.setState({ loading: false })
        }
    }

    _handleSendForm = async (fields, title, incomingAddress, contacts) => {
        let data = Object.assign({}, fields)
        data.phones = this.state.phones
        data.emails = this.state.emails
        this.setState({ loading: true })
        if (incomingAddress.number !== '' && incomingAddress.number !== null) {
            try {
                let address = await CreateAddress(incomingAddress)

                data.address = this.state.equalAddress ? address.data.id : null
                data.addressInvoice = address.data.id
                this.ProcessDataParticipant(data, title, contacts, address.data.id)
            } catch (err) {
                this.setState({ loading: false })
                console.log(err)
            }
        } else {
            data.address = null
            data.addressInvoice = null
            this.ProcessDataParticipant(data, title, contacts, null)
        }
    }

    AssignRepresentative = () => {
        let contact = this.state.contacts.find((e) => e.isRepresentative)
        let fields = this.state.fields
        if (typeof contact !== 'undefined') {
            fields.phoneNumber = contact.phoneNumber
            fields.email = contact.email
            this.setState({ fields })
        }
    }

    _sendFormParticipant = (e) => {
        e.preventDefault()
        let fields = this.state.fields
        let representative = this.state.representative
        if (representative !== -1 && fields.type2 === '1') this.AssignRepresentative()
        let { validateState, validateStateContacts } = this
        let title = this.state.title
        let address = this.state.address
        let contacts = this.state.contacts
        let info_form = validateState('fields', fields_fields)
        let address_form = fields.type !== 4 ? validateState('address', fields_address) : true
        let contact_form = validateStateContacts('contacts', fields_contacts)
        this.setState({ info_form, address_form, contact_form, loading: true })
        let is_valid = info_form && address_form && contact_form ? true : false
        if (is_valid) {
            this._handleSendForm(fields, title, address, contacts)
        } else {
            this.setState({ loading: false })
            AppToaster.show({ icon: 'warning-sign', intent: Intent.DANGER, message: 'Debe completar todos los campos requeridos' })
        }
    }
    _handleMoreOptions = (e) => {
        let val = e.target.getAttribute('data-index')
        this.setState({ moreOptions: val })
    }

    changeRole = (e) => {
        let fields = this.state.fields
        fields.role = e.value
        this.setState({ fields })
    }

    changeType = (e) => {
        let fields = this.state.fields
        fields.type = []

        for (let x = 0; x < e.length; x++) {
            fields.type.push(e[x].value)
        }
        if (this.props.refer > 0) fields.type.push(this.props.refer)
        if (fields.type === 1 || fields.type === 3 || fields.type === 5) {
            if (this.state.contacts.length === 0 || this.state.contacts[0].fullName === '') {
                let contacts = []
                contacts.push({ fullName: '', phoneNumber: '', email: '', CIF: '', role: '', isRepresentative: true })
                this.setState({ contacts })
            }
        }
        this.setState({ fields })
    }

    handleInputChange = (newValue: string) => {
        const inputValue = newValue.replace(/\W/g, '')
        this.setState({ inputValue })
        return inputValue
    }

    _handleChangeAdministor = (e) => {
        let fields = this.state.fields
        if (e !== null) {
            fields.administrator = e.value
            GetContacts('?parent=' + e.value).then((response) => {
                let aux = []
                for (let x = 0; x < response.data.length; x++) {
                    aux.push({ label: response.data[x].fullName, value: response.data[x].id })
                }
                this.setState({ list_contacts: aux, fields, administrators: [e] })
            })
        } else {
            fields.administrator = e
            this.setState({ list_contacts: [], fields })
        }
    }
    _handleChangeReferer = (e) => {
        let fields = this.state.fields
        fields.referer = e.value
        this.setState({ fields, referers: [e] })
    }
    _handleChangeContact = (e) => {
        let fields = this.state.fields
        let aux = []
        for (let i = 0; i < e.length; i++) {
            aux.push(e[i].value)
        }
        fields.manager = aux
        this.setState({ fields })
    }

    loadData = (inputValue, callback, id) => {
        let debounce = this.state.debounce
        let action = ''
        let index = ''
        let global = this
        clearTimeout(debounce)
        debounce = setTimeout(() => {
            if (id === 'province') {
                index = 'provinces'
                action = PaginatedProvince('?name=' + inputValue)
            }
            if (id === 'municipality') {
                index = 'municipalities'
                action = PaginatedMunicipality('?parent=' + this.state.address.province + '&name=' + inputValue)
            }
            if (id === 'via') {
                index = 'vias'
                action = PaginatedVia('?type=&value=&municipality=' + this.state.address.municipality + '&name=' + inputValue)
            }
            if (id === 'administrator') {
                action = PaginatedParticipant('?limit=10&type=1&search=' + inputValue)
            }
            if (id === 'referer') {
                action = PaginatedParticipant('?limit=10&type=4&search=' + inputValue)
            }

            return action
                .then(function (response) {
                    let data = []
                    let results = response.data.results
                    for (let x = 0; x < results.length; x++) {
                        let label = ''
                        if (typeof results[x].name !== 'undefined') {
                            label = results[x].name.toLowerCase().split(' ')
                            for (let i = 0; i < label.length; i++) {
                                label[i] = label[i].charAt(0).toUpperCase() + label[i].slice(1)
                            }
                            label = label.join(' ')
                            if (id === 'via' && results[x].type !== null) label = `${results[x].type} ${label}`
                        } else {
                            label = results[x].fullName.toLowerCase().split(' ')
                            for (let i = 0; i < label.length; i++) {
                                label[i] = label[i].charAt(0).toUpperCase() + label[i].slice(1)
                            }
                            label = label.join(' ')
                        }
                        data.push({ label: label, value: results[x].id })
                    }
                    let state = global.state
                    state[index] = data
                    global.setState(state)
                    return callback(data)
                })
                .catch(function (error) {
                    return callback(null)
                })
        }, 500)
        this.setState({ debounce })
    }

    changeProvince = (e) => {
        let addressText = Object.assign({}, this.state.addressText)
        let fields = Object.assign({}, this.state.fields)
        let address = this.state.address
        if (fields.type.includes(5)) {
            addressText.province = e !== null ? e.label : ''
            fields.fullName = `${addressText.via} ${addressText.number} ${e !== null ? e.label : ''} (${addressText.municipality})`
        }
        address.province = e.value
        address.municipality = null
        address.via = null
        address.number = null
        this.setState({ address, fields, addressText })
    }
    changeMunicipality = (e) => {
        let addressText = Object.assign({}, this.state.addressText)
        let fields = Object.assign({}, this.state.fields)
        let address = this.state.address
        if (fields.type.includes(5)) {
            addressText.municipality = e !== null ? e.label : ''
            fields.fullName = `${addressText.via} ${addressText.number} ${addressText.province} (${e !== null ? e.label : ''})`
        }
        address.municipality = e.value
        address.via = null
        address.number = null
        this.setState({ address, fields, addressText })
    }

    changeVia = (e) => {
        let addressText = Object.assign({}, this.state.addressText)
        let fields = Object.assign({}, this.state.fields)
        let address = this.state.address
        if (fields.type.includes(5)) {
            addressText.via = e !== null ? e.label : ''
            fields.fullName = `${e !== null ? e.label : ''} ${addressText.number} ${addressText.province} (${addressText.municipality})`
        }
        address.via = e.value
        address.number = null
        this.setState({ address })
        let global = this
        GetNumber('?via=' + this.state.address.via).then(function (response) {
            let numbers = []
            let results = response.data
            for (let x = 0; x < results.length; x++) {
                numbers.push({ label: results[x].value, value: results[x].id })
            }
            global.setState({ numbers, fields, addressText })
        })
    }
    changeNumber = (e) => {
        let addressText = Object.assign({}, this.state.addressText)
        let fields = Object.assign({}, this.state.fields)
        let address = this.state.address
        if (fields.type.includes(5)) {
            addressText.number = e !== null ? e.label : ''
            fields.fullName = `${addressText.via} ${e !== null ? e.label : ''} ${addressText.province} (${addressText.municipality})`
        }
        address.number = e !== null ? e.value : e
        this.setState({ address, fields, addressText })
    }

    _handleChangeLegalForm = (e) => {
        let fields = this.state.fields
        fields.legalForm = e.target.value
        let is_autonomo = false
        if (e.target.value === 'AUT') {
            is_autonomo = true
        }
        this.setState({ fields, is_autonomo })
    }
    returnValueSelected = (index, val) => {
        var item = ''
        if (val !== null && val !== undefined) {
            item = this.state[index].find((item) => item.value === val)
            return item
        } else {
            return null
        }
    }
    returnAddressSelected = (index, val) => {
        var item = ''
        if (val !== null && val !== undefined) {
            let grph = this.state.grph
            if (grph !== null) {
                return { value: grph.address[index].id, label: grph.address[index].name ? grph.address[index].name : grph.address[index].value }
            } else {
                item = this.state[index].find((item) => item.value === val)
                return item
            }
        } else {
            return null
        }
    }

    _handleCreateNumber = (val) => {
        let data = {
            parent: this.state.address.province,
            municipality: this.state.address.municipality,
            via: this.state.address.via,
            value: val,
        }
        CreateNumber(data).then((response) => {
            let address = this.state.address
            let fields = Object.assign({}, this.state.fields)
            let numbers = this.state.numbers
            let addressText = Object.assign({}, this.state.addressText)
            address.number = response.data.id
            numbers.push({ label: response.data.value, value: response.data.id })
            if (fields.type.includes(5)) {
                addressText.number = response.data.value
                fields.fullName = `${addressText.via} ${response.data.value} ${addressText.province} (${addressText.municipality})`
                this.setState({ fields, addressText })
            }
            this.setState({ address, numbers })
        })
    }

    _handleAdvanceProfile = () => {
        this.setState({ advanced_profile: !this.state.advanced_profile })
    }
    _handleAdvanceAddress = () => {
        this.setState({ advanced_address: !this.state.advanced_address })
    }
    _handleRepresentative = (e) => {
        let contacts = this.state.contacts
        let new_representative = parseInt(e.target.getAttribute('data-value'))
        for (let x = 0; x < contacts.length; x++) {
            if (new_representative !== x) contacts[x].isRepresentative = false
        }
        contacts[new_representative].isRepresentative = !contacts[new_representative].isRepresentative
        this.setState({ contacts })
    }

    setStep = (e) => {
        let moreOptions = e.target.getAttribute('data-step')
        this.setState({ moreOptions })
    }

    _handleSocialAddress = (e) => {
        this.setState({ equalAddress: e.target.checked })
    }

    getAvailableTypes = (types, type2) => {
        if (type2 === '0') {
            let aux = []
            aux.push({ label: 'Referidor', value: 4 })
            return aux
        } else {
            return types
        }
    }

    addEmail = () => {
        let emails = this.state.emails
        if (emails.length < 3) {
            emails.push({ email: '', type: '1' })
            this.setState({ emails })
        }
    }

    addPhone = () => {
        let phones = this.state.phones
        if (phones.length < 3) {
            phones.push({ phone: '', type: '1' })
            this.setState({ phones })
        }
    }
    removeEmail = (e) => {
        let emails = JSON.parse(JSON.stringify(this.state.emails))
        let index = e.target.getAttribute('data-key')
        emails.splice(parseInt(index), 1)
        this.setState({ emails })
    }

    removePhone = (e) => {
        let phones = JSON.parse(JSON.stringify(this.state.phones))
        let index = e.target.getAttribute('data-key')
        phones.splice(parseInt(index), 1)
        this.setState({ phones })
    }

    changeConditionEstate = (e) => {
        this.setState({ estateIsForProject: e.target.value === '0' ? false : true })
    }

    _handleChangePhone = (e) => {
        let index = e.target.getAttribute('data-index')
        let id = e.target.getAttribute('id')
        let phones = JSON.parse(JSON.stringify(this.state.phones))
        phones[index][id] = e.target.value
        this.setState({ phones })
    }
    _handleChangeEmail = (e) => {
        let index = e.target.getAttribute('data-index')
        let id = e.target.getAttribute('id')
        let emails = JSON.parse(JSON.stringify(this.state.emails))
        emails[index][id] = e.target.value
        this.setState({ emails })
    }

    changeFiles = (files) => {
        let fields = Object.assign({}, this.state.fields)
        fields.files = files
        this.setState({ fields })
    }

    render() {
        // type2 = persona fisica, persona juridica y autonomo
        // type = rol del interviniente Promotor, Profesional, Administrador, Referidor
        let { fields, emails, phones } = this.state
        let { moreOptions, info_form, address_form, contact_form, title, advanced_address, advanced_profile, is_autonomo, grph } = this.state

        return (
            <Modal closeOnOverlayClick={true} classNames={{ modal: 'modal-form', overlay: 'overlay-form' }} open={this.props.open} onClose={this.props.onClose} closeOnEsc={true} center>
                <h4 className="title-modal">
                    {grph !== null ? 'Editar' : 'Nuevo'} {title}
                </h4>
                <form onSubmit={this._sendFormParticipant}>
                    <div className="body-modal">
                        <HeaderForm
                            moreOptions={moreOptions}
                            address_form={address_form}
                            contact_form={contact_form}
                            is_autonomo={is_autonomo}
                            info_form={info_form}
                            type={this.state.fields.type2}
                            handleMoreOptions={this._handleMoreOptions}
                        />

                        {this.state.moreOptions === 'ParticipantInfo' ? (
                            <ParticipantInfo
                                state={this.state}
                                refer={this.props.refer}
                                fields={fields}
                                setStep={this.setStep}
                                handleChangeField={this._handleChangeField}
                                handleChangeLegalForm={this.handleChangeLegalForm}
                                changeType={this.changeType}
                                returnValueSelected={this.returnValueSelected}
                                getAvailableTypes={this.getAvailableTypes}
                                loadData={this.loadData}
                                cleanFields={this.cleanFields}
                                changeFiles={this.changeFiles}
                                onClose={this.props.onClose}
                            />
                        ) : null}
                        {this.state.moreOptions === 'ContactParticipantInfo' ? (
                            <ContactParticipantInfo
                                state={this.state}
                                fields={this.state.fields}
                                handleChangePhone={this._handleChangePhone}
                                handleChangeEmail={this._handleChangeEmail}
                                setStep={this.setStep}
                                addEmail={this.addEmail}
                                addPhone={this.addPhone}
                                removeEmail={this.removeEmail}
                                removePhone={this.removePhone}
                                handleChangeField={this._handleChangeField}
                                cleanFields={this.cleanFields}
                                onClose={this.props.onClose}
                            />
                        ) : null}
                        {this.state.moreOptions === 'Address' ? (
                            <div className="row fade-in">
                                <h4 className="title-body-modal">Dirección Fiscal</h4>
                                <hr />

                                {fields.type.includes(2) && (
                                    <div className="row pt-3 px-2">
                                        <div className="col-12">
                                            <Switch label="Coincide con dirección social" checked={this.state.equalAddress} onChange={this._handleSocialAddress} />
                                        </div>
                                    </div>
                                )}
                                {fields.type.includes(2) && (
                                    <div className="col-12">
                                        <label className="label-form">
                                            <span className="field-name">
                                                Asociar finca para proyecto
                                                <select className="form-control" onChange={this.changeConditionEstate}>
                                                    <option value="1">Si</option>
                                                    <option value="0" selected>
                                                        No
                                                    </option>
                                                </select>
                                            </span>
                                        </label>
                                    </div>
                                )}
                                <div className="col-12">
                                    <label className="label-form">
                                        <span className="field-name">
                                            Provincia <span className="required">*</span>
                                        </span>
                                        <AsyncSelect
                                            classNamePrefix="react-select"
                                            id="province"
                                            styles={customStyles}
                                            defaultValue={this.returnAddressSelected(grph !== null ? 'province' : 'provinces', this.state.address.province)}
                                            value={this.returnValueSelected('provinces', this.state.address.province)}
                                            placeholder=""
                                            noOptionsMessage={() => null}
                                            loadOptions={(inputValue, callback) => this.loadData(inputValue, callback, 'province')}
                                            defaultOptions
                                            onChange={this.changeProvince}
                                        />
                                    </label>
                                </div>
                                <div className="col-12">
                                    <label className="label-form">
                                        <span className="field-name">
                                            Municipio <span className="required">*</span>
                                        </span>
                                        <AsyncSelect
                                            classNamePrefix="react-select"
                                            id="municipality"
                                            styles={customStyles}
                                            defaultValue={this.returnAddressSelected(grph !== null ? 'municipality' : 'municipalities', this.state.address.municipality)}
                                            placeholder=""
                                            value={this.returnValueSelected('municipalities', this.state.address.municipality)}
                                            noOptionsMessage={() => null}
                                            onChange={this.changeMunicipality}
                                            loadOptions={(inputValue, callback) => this.loadData(inputValue, callback, 'municipality')}
                                            defaultOptions
                                        />
                                    </label>
                                </div>
                                <div className="col-12">
                                    <label className="label-form">
                                        <span className="field-name">
                                            Via <span className="required">*</span>
                                        </span>
                                        <AsyncSelect
                                            classNamePrefix="react-select"
                                            id="via"
                                            styles={customStyles}
                                            defaultValue={this.returnAddressSelected(grph !== null ? 'via' : 'vias', this.state.address.via)}
                                            placeholder=""
                                            value={this.returnValueSelected('vias', this.state.address.via)}
                                            noOptionsMessage={() => null}
                                            onChange={this.changeVia}
                                            loadOptions={(inputValue, callback) => this.loadData(inputValue, callback, 'via')}
                                            defaultOptions
                                        />
                                    </label>
                                </div>
                                <div className="col-12">
                                    <label className="label-form">
                                        <span className="field-name">
                                            Número <span className="required">*</span>
                                        </span>
                                        <CreatableSelect
                                            id="number"
                                            isClearable
                                            onCreateOption={this._handleCreateNumber}
                                            defaultValue={this.returnAddressSelected(grph !== null ? 'number' : 'numbers', this.state.address.number)}
                                            value={this.returnValueSelected('numbers', this.state.address.number)}
                                            placeholder=""
                                            noOptionsMessage={() => null}
                                            onChange={this.changeNumber}
                                            options={this.state.numbers}
                                        />
                                    </label>
                                </div>
                                {title !== 'Comunidad' && advanced_address && (
                                    <div className="col-4">
                                        <label className="label-form">
                                            <span className="field-name">Escalera</span>
                                            <input autoComplete="off" onChange={this._handleChangeAddress} value={this.state.address.floor} id="floor" type="text" className="form-control" />
                                        </label>
                                    </div>
                                )}
                                {title !== 'Comunidad' && advanced_address && (
                                    <div className="col-4">
                                        <label className="label-form">
                                            <span className="field-name">Planta</span>
                                            <input autoComplete="off" onChange={this._handleChangeAddress} value={this.state.address.chairs} id="chairs" type="text" className="form-control" />
                                        </label>
                                    </div>
                                )}
                                {title !== 'Comunidad' && advanced_address && (
                                    <div className="col-4">
                                        <label className="label-form">
                                            <span className="field-name">Puerta</span>
                                            <input autoComplete="off" onChange={this._handleChangeAddress} value={this.state.address.door} id="door" type="text" className="form-control" />
                                        </label>
                                    </div>
                                )}
                                <div className="col-12">
                                    <label className="label-form">
                                        <span className="field-name">Código postal</span>
                                        <input autoComplete="off" onChange={this._handleChangeAddress} value={this.state.address.zipCode} id="zipCode" type="number" className="form-control" />
                                    </label>
                                </div>
                                {title !== 'Comunidad' && (
                                    <div className="col-12">
                                        <a className="mx-3" onClick={this._handleAdvanceAddress}>
                                            <small style={{ color: '#1081c7' }}>{!advanced_address ? 'Avanzado' : 'Rápido'}</small>
                                        </a>
                                    </div>
                                )}
                                <div className="col-12 text-right">
                                    <button className="cancel-button" data-step="ContactParticipantInfo" onClick={this.setStep}>
                                        Atrás
                                    </button>
                                    <button className="create-button" data-step="ContactList" onClick={this.setStep}>
                                        Siguiente
                                    </button>
                                </div>
                            </div>
                        ) : null}
                        {this.state.moreOptions === 'ContactList' ? (
                            <div className="row fade-in">
                                <h4 className="title-body-modal">Contactos asociados</h4>
                                <hr />
                                {this.state.contacts.length === 0 && fields.type2 === '1' && !this.state.contact_form && <h6 className="title-body-modal danger">Debes agregar un contacto.</h6>}
                                {this.state.contacts.map((el, key) => (
                                    <div key={key} className="col-12 pb-3 fade-in">
                                        <div className="row">
                                            <div className="col-12">
                                                {fields.type2 === '1' && (
                                                    <Switch label="Representante" name="representative" data-value={key} onChange={this._handleRepresentative} checked={el.isRepresentative} />
                                                )}
                                                <a className="remove-contact" data-index={key} onClick={this._removeContact}>
                                                    <i className="icon fas fa-times" />
                                                </a>
                                                <label className="label-form">
                                                    <span className="field-name">Nombre/Apellidos</span>
                                                    <input
                                                        autoComplete="off"
                                                        required
                                                        onChange={this._handleChangeContactField}
                                                        value={el.fullName}
                                                        data-index={key}
                                                        id="fullName"
                                                        type="text"
                                                        className="form-control"
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-12">
                                                <label className="label-form">
                                                    <span className="field-name" required>
                                                        Correo electrónico
                                                    </span>{' '}
                                                    <input
                                                        autoComplete="off"
                                                        required
                                                        onChange={this._handleChangeContactField}
                                                        value={el.email}
                                                        data-index={key}
                                                        id="email"
                                                        type="email"
                                                        className="form-control"
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-12">
                                                <label className="label-form">
                                                    <span className="field-name">Teléfono</span>
                                                    <InputMask
                                                        mask="99 999 99 99"
                                                        autoComplete="off"
                                                        onChange={this._handleChangeContactField}
                                                        value={el.phoneNumber}
                                                        data-index={key}
                                                        id="phoneNumber"
                                                        type="tel"
                                                        className="form-control"
                                                    />
                                                </label>
                                            </div>

                                            <div className="col-12">
                                                <label className="label-form">
                                                    <span className="field-name">Cargo</span>
                                                    <Select
                                                        classNamePrefix="react-select"
                                                        placeholder="Seleccionar"
                                                        noOptionsMessage={() => null}
                                                        onChange={(e) => this._changeContactRole(e, key)}
                                                        options={this.state.contactRoles}
                                                        defaultValue={this.returnValueSelected('contactRoles', el.role)}
                                                    />
                                                </label>
                                            </div>
                                            <div className="col-12">
                                                <label className="label-form">
                                                    <span className="field-name">DNI/NIE/Pasaporte</span>
                                                    <input autoComplete="off" onChange={this._handleChangeContactField} value={el.CIF} data-index={key} id="CIF" type="text" className="form-control" />
                                                </label>
                                            </div>

                                            <hr style={{ position: 'realtive', float: 'left', width: '100%', left: '100%' }} />
                                        </div>
                                    </div>
                                ))}
                                <button type="button" className="mx-5 create-button-small add-more-contacts" onClick={this._addContact}>
                                    <i className="icon fas fa-plus" />
                                </button>
                                <div className="row py-12 mb-3">
                                    <div className="col-12 text-right">
                                        <button type="button" className="cancel-button" data-step="Address" onClick={this.setStep}>
                                            Atrás
                                        </button>
                                        {this.state.loading ? (
                                            <button type="button" className="create-button">
                                                <img style={{ width: '25px' }} src={IconLoading} alt="loading" />
                                            </button>
                                        ) : (
                                            <button type="submit" className="create-button">
                                                {' '}
                                                {grph === null ? 'Crear ' + title : 'Actualizar ' + title}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </form>
            </Modal>
        )
    }
}

export default connect(getState, Dispatch)(ModalContact)
