import { API } from './main'

export function GetDocuments() {
    return API('/files/')
}
export function GetDocument(id) {
    return API('/files/edit/' + id + '/')
}
export function FilterDocuments(qs) {
    return API('/files/?' + qs)
}
export function EditDocument(id, data) {
    return API.patch('/files/edit/' + id + '/', data)
}
export function GetDocumentsByContact(id) {
    return API('/files/?contact=' + id)
}
export function GetDocumentsByDeal(id) {
    return API('/files/?deal=' + id)
}
export function GetDocumentsByEstate(id) {
    return API('/files/?estate=' + id)
}
export function GetDocumentsByParticipant(id) {
    return API('/files/?participant=' + id)
}
export function DeleteFile(id) {
    return API.delete('/files/delete/' + id + '/')
}
export function DeleteMedia(uid) {
    return API.delete('/files/media/delete/' + uid + '/')
}
export function NewFile(data) {
    return API.post('/files/new/', data)
}
export function NewMedia(data) {
    return API.post('/files/media/new/', data)
}
