import React, { Component } from 'react'
import Cookies from 'universal-cookie'
import Logo from '../../static/images/logo.png'
import { connect } from 'react-redux'
import axios from 'axios'
import { SERVER_URL } from '../../API/main'
import { Spinner } from '@blueprintjs/core'
import styled from 'styled-components'
import { PrivateMutate } from '../../API/Apollo'
import { updateHeaders } from '../../API/main'
// Constantes
const cookies = new Cookies()

const getState = (state) => {
    return {
        login: state.login,
    }
}
const Dispatch = (dispatch) => {
    return {
        Login: (data) => {
            const action = {
                type: 'Login',
                data: data,
            }
            dispatch(action)
        },
    }
}

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            login: {
                email: '',
                show: false,
                password: '',
            },
            error: false,
        }
    }

    handleChangeField = (e) => {
        let login = this.state.login
        let id = e.target.getAttribute('id')
        login[id] = e.target.value
        this.setState({ login })
    }
    Login = (e) => {
        this.setState({ loading: true })
        e.preventDefault()
        this.setState({ error: false })
        let funcion = this.props.Login
        axios
            .post(SERVER_URL + '/login/', this.state.login)
            .then((response) => {
                updateHeaders({
                    Authorization: `Token ${response.data.token}`,
                })
                let data = response.data
                let query = ` mutation { tokenAuth(email: "${this.state.login.email}", password:"${this.state.login.password}"){ token } }`
                cookies.set('csrf', data.csrf, { path: '/' })
                cookies.set('token', data.token, { path: '/' })
                cookies.set('key', data.key, { path: '/' })
                cookies.set('membership', data.membership, { path: '/' })
                cookies.set('email', data.email, { path: '/' })
                cookies.set('uid', data.uid, { path: '/' })
                cookies.set('cuid', data.cid, { path: '/' })
                PrivateMutate(query).then((response) => {
                    cookies.set('tokeng', response.data.tokenAuth.token, { path: '/' })
                })
                funcion(JSON.stringify(response.data))
            })
            .catch((err) => {
                setTimeout(() => {
                    this.setState({ error: true, loading: false })
                }, 1000)
            })
    }

    componentDidMount = () => {
        let token = cookies.get('token')
        let funcion = this.props.Login
        if (token !== undefined) {
            axios.post(SERVER_URL + '/validateToken/', { token: token }).then((response) => {
                updateHeaders({
                    Authorization: `Token ${response.data.token}`,
                })
                if (response.data?.response !== false) {
                    funcion(JSON.stringify(response.data))
                } else {
                    this.setState({ show: true })
                }
            })
        } else {
            this.setState({ show: true })
        }
    }

    _handleShow = () => {
        this.setState({ show: !this.state.show })
    }

    render() {
        return (
            <div id="container" className="container-login">
                <div className="registro">
                    <div className="row card inside-register">
                        <div className="logo-initial center-align">
                            <img src={Logo} />
                        </div>
                        <form>
                            <div className="col s12 input-field ">
                                <input placeholder="Correo electrónico" type="email" className="field-login" name="email" id="email" onChange={this.handleChangeField} value={this.state.email} />
                            </div>
                            <div className="col s12 input-field ">
                                <input
                                    style={{ paddingRight: '30px' }}
                                    placeholder="Contraseña"
                                    type={!this.state.show ? 'text' : 'password'}
                                    className="field-login"
                                    name="password"
                                    id="password"
                                    onChange={this.handleChangeField}
                                    value={this.state.password}
                                />
                                <SpanEye onClick={this._handleShow}>{!this.state.show ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>}</SpanEye>
                            </div>
                            <div className="col s12 input-field">
                                {!this.state.loading ? (
                                    <button onClick={this.Login} className="btn-large waves-button-input" style={{ backgroundColor: '#115d8d' }}>
                                        Iniciar sesión
                                    </button>
                                ) : (
                                    <button className="btn-large waves-button-input" style={{ backgroundColor: '#115d8d' }}>
                                        <Spinner size={20} />
                                    </button>
                                )}
                            </div>
                            {this.state.error && (
                                <div className="text-center mt-3">
                                    <small className="danger">Datos de acceso invalidos</small>
                                </div>
                            )}
                        </form>
                    </div>
                    <div className="col s6 right forgot-password">
                        <small>
                            <a href="/password_reset/">¿Contraseña perdida?</a>
                        </small>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(getState, Dispatch)(Login)

const SpanEye = styled.span`
    position: absolute;
    float: right;
    right: 20px;
    top: 20px;
    color: #555;
    cursor: pointer;
    transition: all 0.3s ease;
    :hover {
        color: #777;
    }
`
