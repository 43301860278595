import { API } from './main';

export const CreateAddress = (data) => API.post('/address/create/', data)
export const CreateNumber = (data) => API.post('/address/number/new/', data)
export const PaginatedAddress = (params) => API.get('/address/paginated/' + params)
export const PaginatedProvince = (params) => API.get('/address/provinces/paginated/' + params)
export const PaginatedMunicipality = (params) => API.get('/address/municipality/paginated/' + params)
export const PaginatedVia = (params) => API.get('/address/via/paginated/' + params)
export const GetNumber = (params) => API.get('/address/number/' + params)
export const AllAddress = (params) => API.get('/address/' + params)
export const RetrieveAddress = (pk) => API.get('/address/' + pk + '/')
export const AddressByParticipant = (pk) => API.get('/address/participant/' + pk + '/')
export const AddressByEstate = (pk) => API.get('/address/estate/' + pk + '/')
export const DeleteAddress = (pk) => API.delete('/address/delete/' + pk + '/')
export const UpdateAddress = (pk, data) => API.patch('/address/update/' + pk + '/', data)
