import React, { Component } from 'react'
import Logo from '../static/images/logo-white.png'
import escritorio from '../static/images/icon/dashboard-white.png'
import mail from '../static/images/icon/mail-white.png'
import fincas from '../static/images/icon/estate-white.png'
import proyectos from '../static/images/icon/projects-white.png'
import juridicoNegativo from '../static/images/icon/icon-administrador-white.png'
import presupuestos from '../static/images/icon/budget-white.png'
import facturas from '../static/images/icon/invoice-white.png'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { connect } from 'react-redux'

const getState = (state) => {
    return {
        permissions: state.permissions,
    }
}

const Dispatch = (dispatch) => {
    return {
        setCurrent: () => {
            const action = {
                type: 'SetCurrent',
                tab: '',
                current: null,
            }
            dispatch(action)
        },
    }
}

class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active: 1,
            subMenu: false,
        }
    }
    _handleActive = (e) => {
        let active = parseInt(e.target.getAttribute('data-index'))
        this.setState({ active })
        this.props.onClose()
    }

    showSubMenu = () => {
        this.setState({ subMenu: !this.state.subMenu })
    }
    render() {
        return (
            <div id="nav-mobile" className="sidebar-mobile">
                <div className="logo">
                    <img src={Logo} />
                </div>
                <ul>
                    <li
                        data-index="1"
                        onClick={(e) => {
                            this.props.setCurrent()
                            this._handleActive(e)
                        }}
                        className={this.state.active === 1 ? 'list-menu text-left active' : 'list-menu text-left'}>
                        <Link
                            data-index="1"
                            onClick={(e) => {
                                this.props.setCurrent()
                                this._handleActive(e)
                                this.showSubMenu(false)
                            }}
                            to="/"
                            className="waves-effect d-block">
                            <p className="text-left" data-index="1">
                                <img className="pointer-ignore" src={escritorio} /> <span className="mx-2">Escritorio</span>
                            </p>
                        </Link>
                    </li>
                    {/* <li
                        data-index="2"
                        onClick={(e) => {
                            this.props.setCurrent()
                            this._handleActive(e)
                            this.showSubMenu(false)
                        }}
                        className={this.state.active === 2 ? 'list-menu text-left active' : 'list-menu text-left'}>
                        <Link
                            data-index="2"
                            onClick={(e) => {
                                this.props.setCurrent()
                                this._handleActive(e)
                                this.showSubMenu(false)
                            }}
                            to="/mail"
                            className="waves-effect d-block">
                            <p className="text-left" data-index="2">
                                <img className="pointer-ignore" src={mail} /> <span className="mx-2">Correos</span>
                            </p>
                        </Link>
                    </li> */}
                    {typeof this.props.permissions !== 'undefined' && this.props.permissions.accessEstate && (
                        <li
                            data-index="3"
                            onClick={(e) => {
                                this.props.setCurrent()
                                this._handleActive(e)
                                this.showSubMenu(false)
                            }}
                            className={this.state.active === 3 ? 'list-menu text-left active' : 'list-menu text-left'}>
                            <Link
                                data-index="3"
                                onClick={(e) => {
                                    this.props.setCurrent()
                                    this._handleActive(e)
                                    this.showSubMenu(false)
                                }}
                                to="/fincas"
                                className="waves-effect d-block">
                                <p className="text-left" data-index="3">
                                    <img className="pointer-ignore" src={fincas} /> <span className="mx-2">Fincas</span>
                                </p>
                            </Link>
                        </li>
                    )}
                    {/* {typeof this.props.permissions !== 'undefined' && this.props.permissions.accessProject && (
                        <li
                            data-index="4"
                            onClick={(e) => {
                                this.props.setCurrent()
                                this._handleActive(e)
                                this.showSubMenu(false)
                            }}
                            className={this.state.active === 4 ? 'list-menu text-left active' : 'list-menu text-left'}>
                            <Link
                                data-index="4"
                                onClick={(e) => {
                                    this.props.setCurrent()
                                    this._handleActive(e)
                                    this.showSubMenu(false)
                                }}
                                to="/proyectos"
                                className="waves-effect d-block">
                                <p className="text-left" data-index="4">
                                    <img className="pointer-ignore" src={proyectos} /> <span className="mx-2">Proyectos</span>
                                </p>
                            </Link>
                        </li>
                    )} */}
                    {typeof this.props.permissions !== 'undefined' && this.props.permissions.accessParticipant && (
                        <li
                            data-index="5"
                            className="list-menu text-left"
                            onClick={(e) => {
                                this.props.setCurrent()
                                this._handleActive(e)
                            }}>
                            <Link
                                data-index="5"
                                onClick={(e) => {
                                    this.props.setCurrent()
                                    this._handleActive(e)
                                }}
                                to="/participantes"
                                className="waves-effect d-block">
                                <p className="text-left" data-index="5">
                                    <img className="pointer-ignore" src={juridicoNegativo} /> <span className="mx-2">Intervinientes</span>
                                </p>
                            </Link>
                        </li>
                    )}
                    {typeof this.props.permissions !== 'undefined' && this.props.permissions.accessBudget && (
                        <li
                            data-index="7"
                            onClick={(e) => {
                                this.props.setCurrent()
                                this._handleActive(e)
                                this.showSubMenu(false)
                            }}
                            className={this.state.active === 7 ? 'list-menu text-left active' : 'list-menu text-left'}>
                            <Link
                                data-index="7"
                                onClick={(e) => {
                                    this.props.setCurrent()
                                    this._handleActive(e)
                                    this.showSubMenu(false)
                                }}
                                to="/presupuestos"
                                className="waves-effect d-block">
                                <p className="text-left" data-index="7">
                                    <img className="pointer-ignore" src={presupuestos} />
                                    <span className="mx-2">Presupuestos</span>
                                </p>
                            </Link>
                        </li>
                    )}
                    {typeof this.props.permissions !== 'undefined' && this.props.permissions.accessInvoice && (
                        <li
                            data-index="8"
                            onClick={(e) => {
                                this.props.setCurrent()
                                this._handleActive(e)
                                this.showSubMenu(false)
                            }}
                            className={this.state.active === 8 ? 'list-menu text-left active' : 'list-menu text-left'}>
                            <Link
                                data-index="8"
                                onClick={(e) => {
                                    this.props.setCurrent()
                                    this._handleActive(e)
                                    this.showSubMenu(false)
                                }}
                                to="/facturas"
                                className="waves-effect d-block">
                                <p className="text-left" data-index="8">
                                    <img className="pointer-ignore" src={facturas} />
                                    <span className="mx-2">Facturas</span>
                                </p>
                            </Link>
                        </li>
                    )}
                    {typeof this.props.permissions !== 'undefined' && this.props.permissions.accessConfig && (
                        <li
                            data-index="9"
                            onClick={(e) => {
                                this.props.setCurrent()
                                this._handleActive(e)
                                this.showSubMenu(false)
                            }}
                            className={this.state.active === 9 ? 'list-menu text-left active' : 'list-menu text-left'}>
                            <Link
                                data-index="9"
                                onClick={(e) => {
                                    this.props.setCurrent()
                                    this._handleActive(e)
                                    this.showSubMenu(false)
                                }}
                                to="/parametrizacion"
                                className="waves-effect d-block">
                                <p className="text-left" data-index="9">
                                    <i className="icon fas fa-cog" style={{ fontSize: '19px' }} /> <span className="mx-2">Parametrización</span>
                                </p>
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
        )
    }
}

export default connect(getState, Dispatch)(Sidebar)

const Li = styled.li`
    transition: all 0.3s ease;
    cursor: pointer;
    font-size: 13px;
    text-align: left;
    width: 100%;
    position: relative;
    float: left;
    color: #fff !important;
    a {
        font-size: 13px !important;
        font-weight: 500;
        color: #fff;
        text-decoration: none;
        width: 100%;
        overflow: hidden;
        position: relative;
        float: left;
        height: 100%;
        padding: 10px 15px !important;
        color: #fff !important;
    }
    img {
        width: 20px;
        margin-right: 10px;
    }
`
