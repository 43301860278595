import { API } from './main'

export const CreateEstate = (data) => API.post('/estates/', data)
export const PaginatedEstate = (params) => API.get('/estates/' + params)
export const RetrieveEstate = (pk) => API.get('/estates/' + pk + '/')
export const DeleteEstate = (pk) => API.delete('/estates/' + pk + '/')
export const SendEstate = (pk) => API.post('/estates/' + pk + '/send/')
export const DuplicateEstate = (pk) => API.get('/estates/' + pk + '/duplicate/')
export const GetParticipantsEstate = (pk) => API.get('/estates/' + pk + '/participants/')
export const UpdateEstate = (pk, data) => API.patch('/estates/' + pk + '/', data)
export const UpdateEstateAdress = (pk, data) => API.patch('/estates/' + pk + '/update_address/', data)

export const PaginatedType = (params) => API.get('/estates/types/' + params)
export const CreateType = (data) => API.post('/estates/types/', data)
export const UpdateType = (pk, data) => API.patch(`/estates/types/${pk}/`, data)
export const RetrieveType = (pk) => API.get(`/estates/types/${pk}/`)
export const DeleteType = (pk) => API.delete(`/estates/types/${pk}/`)

export const PaginatedNote = (params) => API.get('/estates/observations/' + params)
export const CreateNote = (data) => API.post('/estates/observations/', data)
export const UpdateNote = (pk, data) => API.patch(`/estates/observations/${pk}/`, data)
export const RetrieveNote = (pk) => API.get(`/estates/observations/${pk}/`)
export const DeleteNote = (pk) => API.delete(`/estates/observations/${pk}/`)
