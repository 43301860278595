import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import asyncComponent from '../Components/asyncComponent'
import { RemountingRoute } from '../Components/RemountingRoute'
const Dashboard = asyncComponent(() => import('../Pages/Dashboard/index'))
const Budget = asyncComponent(() => import('../Pages/Budget/index'))
const Estates = asyncComponent(() => import('../Pages/Estates/index'))
const DetailEstates = asyncComponent(() => import('../Pages/Estates/detail'))
// const DetailProject = asyncComponent(() => import('../Pages/Projects/detail'))
const DetailParticipant = asyncComponent(() => import('../Pages/Participants/detail'))
const DetailContact = asyncComponent(() => import('../Pages/Contacts/detail'))
const DetailInvoice = asyncComponent(() => import('../Pages/Invoice/detail'))
const DetailBudget = asyncComponent(() => import('../Pages/Budget/detail'))
const FormBudget = asyncComponent(() => import('../Pages/Budget/form'))
const FormInvoice = asyncComponent(() => import('../Pages/Invoice/form'))
const Invoice = asyncComponent(() => import('../Pages/Invoice/index'))
const Mail = asyncComponent(() => import('../Pages/Mail/index'))
const Participants = asyncComponent(() => import('../Pages/Participants/participants'))
const Projects = asyncComponent(() => import('../Pages/Projects/index'))
// const Pipeline = asyncComponent(() => import('../Pages/Projects/pipeline'))
const Config = asyncComponent(() => import('../Pages/Config/index'))

class Content extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        let permissions = this.props.permissions
        return (
            <div id="principal" className={`container `}>
                <Route path="/" exact component={Dashboard}></Route>
                <Route path="/mail" exact component={Mail}></Route>
                {typeof permissions !== 'undefined' && permissions.accessEstate && <Route path="/fincas" exact component={() => <Estates params={this.props.location.search} />}></Route>}
                {typeof permissions !== 'undefined' && permissions.accessEstate && <Route path="/fincas/ver/:id" exact component={DetailEstates}></Route>}
                {typeof permissions !== 'undefined' && permissions.accessProject && <Route path="/proyectos" exact component={() => <Projects params={this.props.location.search} />}></Route>}
                {/* {typeof permissions !== 'undefined' && permissions.accessProjects && <Route path="/proyectos/embudo" exact component={Pipeline}></Route>} */}
                {/* {typeof permissions !== 'undefined' && permissions.accessProjects && <Route path="/proyectos/ver/:uid" exact component={DetailProject}></Route>} */}
                {typeof permissions !== 'undefined' && permissions.accessParticipant && (
                    <Route path="/participantes" exact component={() => <Participants params={this.props.location.search} />}></Route>
                )}
                {typeof permissions !== 'undefined' && permissions.accessParticipant && <Route path="/contactos/ver/:id" exact component={DetailContact}></Route>}
                {typeof permissions !== 'undefined' && permissions.accessParticipant && <Route path="/participantes/ver/:id" exact component={DetailParticipant}></Route>}
                {typeof permissions !== 'undefined' && permissions.accessInvoice && <Route path="/facturas" exact component={() => <Invoice params={this.props.location.search} />}></Route>}
                {typeof permissions !== 'undefined' && permissions.accessInvoice && <Route path="/facturas/nuevo" exact component={FormInvoice}></Route>}
                {typeof permissions !== 'undefined' && permissions.accessInvoice && <RemountingRoute path="/facturas/ver/:id" exact component={DetailInvoice}></RemountingRoute>}
                {typeof permissions !== 'undefined' && permissions.accessBudget && <Route path="/facturas/editar/:id" exact component={FormInvoice}></Route>}
                {typeof permissions !== 'undefined' && permissions.accessBudget && <Route path="/presupuestos" exact component={() => <Budget params={this.props.location.search} />}></Route>}
                {typeof permissions !== 'undefined' && permissions.accessBudget && <RemountingRoute path="/presupuestos/ver/:id" exact component={DetailBudget}></RemountingRoute>}
                {typeof permissions !== 'undefined' && permissions.accessBudget && <Route path="/presupuestos/editar/:id" exact component={FormBudget}></Route>}
                {typeof permissions !== 'undefined' && permissions.accessBudget && <Route path="/presupuestos/nuevo" exact component={FormBudget}></Route>}
                {typeof permissions !== 'undefined' && permissions.accessConfig && <Route path="/parametrizacion" component={Config}></Route>}
            </div>
        )
    }
}

export default withRouter(Content)
