import React from 'react'
import InputMask from 'react-input-mask'
import Select from 'react-select'
import AsyncSelect from 'react-select/lib/Async'
import styled from 'styled-components'
import { InputFile } from '../../Fields'
const customStyles = {
    dropdownIndicator: (provided, state) => ({
        display: 'none',
    }),
    loadingIndicator: (provided, state) => ({
        display: 'none',
    }),
    indicatorSeparator: (provided, state) => ({
        display: 'none',
    }),
}

export default function ParticipantInfo(props) {
    const [advanced_profile, setAdvanced_profile] = React.useState(false)
    return (
        <ContentParticipantInfo>
            <div className="row">
                <h4 className="title-body-modal">{props.state.title === 'Comunidad' ? `Datos de la ${props.state.title}` : `Datos del ${props.state.title}`}</h4>
                <div className="col-12 text-left">
                    <label className="label-form">
                        <small>
                            Forma legal <span className="required">*</span>
                        </small>
                        {props.refer !== 5 ? (
                            <select required onChange={props.handleChangeField} id="type2" className="form-control" value={props.fields.type2}>
                                {props.refer !== 3 && <option value="0">Persona Física</option>}
                                <option value="1" selected>
                                    Persona Jurídica
                                </option>
                                <option value="2">Autónomo</option>
                            </select>
                        ) : (
                            <select required onChange={props.handleChangeLegalForm} id="legalForm" className="form-control" value={props.fields.legalForm}>
                                {props.state.legalForms.map((e, key) => (
                                    <option key={key} value={e.id}>
                                        {e.name}
                                    </option>
                                ))}
                            </select>
                        )}
                    </label>
                </div>
                {props.refer !== 5 && (
                    <div className="col-12">
                        <label className="label-form">
                            <small className="field-name">
                                {props.fields.type2 !== '1' || props.state.is_autonomo ? 'Nombre/Apellidos' : 'Razón social'} <span className="required">*</span>
                            </small>
                            <input autoComplete="off" onChange={props.handleChangeField} value={props.fields.fullName} id="fullName" required type="text" className="form-control" />
                        </label>
                    </div>
                )}
                {props.fields.type2 !== '0' && props.fields.type2 !== '2' && (
                    <div className="col-12">
                        <label className="label-form">
                            <small className="field-name">Nombre comercial</small>
                            <input autoComplete="off" onChange={props.handleChangeField} value={props.fields.commercialName} id="commercialName" type="text" className="form-control" />
                        </label>
                    </div>
                )}
                <div className="col-12">
                    <label className="label-form">
                        <small className="field-name">{props.fields.type2 === '0' ? 'DNI/NIE/Pasaporte' : 'CIF'}</small>
                        <InputMask
                            mask="*9999999*"
                            autoComplete="off"
                            required={props.is_invoice}
                            onChange={props.handleChangeField}
                            value={props.fields.CIF}
                            id="CIF"
                            type="text"
                            className="form-control"
                        />
                    </label>
                </div>
                {props.refer === 2 && (
                    <div className="col-12">
                        <label className="label-form">
                            <small className="field-name">Tipo de promotor</small>
                            <select className="form-control">
                                <option value="1">Promotor inmobiliario</option>
                                <option value="2">Promotor entidad/es</option>
                                <option value="3" selected>
                                    Propietario finca
                                </option>
                            </select>
                        </label>
                    </div>
                )}
                {props.refer !== 4 && props.refer !== 5 && (
                    <div className="col-12">
                        <label className="label-form">
                            <small className="field-name">Roles adicionales</small>
                            <Select
                                classNamePrefix="react-select"
                                id="type"
                                isMulti
                                placeholder="Seleccionar"
                                noOptionsMessage={() => null}
                                onChange={props.changeType}
                                defaultValue={props.returnValueSelected('type', props.refer ? props.refer : props.fields.type)}
                                options={props.getAvailableTypes(props.state.type, props.fields.type2)}
                            />
                        </label>
                    </div>
                )}
                {advanced_profile && props.fields.type.includes(4) && (
                    <>
                        <div className="col-12">
                            <label className="label-form">
                                <small className="field-name">Tipo de comisión</small>
                                <select id="comisionType" className="form-control" value={props.fields.comisionType} onChange={props.handleChangeField}>
                                    <option value="1">Porcentual</option>
                                    <option value="2">Monto Exacto</option>
                                </select>
                            </label>
                        </div>
                        <div className="col-12">
                            <label className="label-form">
                                {props.fields.comisionType === '1' ? <small className="field-name">Comisión (%)</small> : <small className="field-name">Comisión (€)</small>}
                                <input type="number" id="comision" className="form-control" value={props.fields.comision} onChange={props.handleChangeField} />
                            </label>
                        </div>
                    </>
                )}
                {advanced_profile && (!props.fields.type.includes(4) || props.fields.type.length > 1) && (
                    <div className="col-12">
                        <label className="label-form">
                            <small className="field-name">Descuento (%)</small>
                            <input type="number" id="discount" className="form-control" value={props.fields.discount} onChange={props.handleChangeField} />
                        </label>
                    </div>
                )}

                {advanced_profile && props.fields.type.includes(2) && (
                    <>
                        <div className="col-12">
                            <label className="label-form">
                                <small className="field-name">Administrador</small>
                                <AsyncSelect
                                    classNamePrefix="react-select"
                                    isClearable
                                    styles={customStyles}
                                    defaultValue={props.returnValueSelected('administrators', props.fields.administrator)}
                                    placeholder="Buscar administrador"
                                    noOptionsMessage={() => 'No existe registro'}
                                    loadOptions={(inputValue, callback) => props.loadData(inputValue, callback, 'administrator')}
                                    defaultOptions
                                    onChange={props.handleChangeAdministor}
                                />
                            </label>
                        </div>
                        {props.fields.administrator !== '' && (
                            <div className="col-12">
                                <label className="label-form">
                                    <small className="field-name">Gestor/Auxiliar</small>
                                    <Select
                                        classNamePrefix="react-select"
                                        isMulti
                                        styles={customStyles}
                                        defaultValue={props.returnValueSelected('list_contacts', props.fields.manager)}
                                        placeholder="Buscar gestor"
                                        noOptionsMessage={() => 'No existe registro'}
                                        onChange={props.handleChangeContact}
                                        options={props.state.list_contacts}
                                        id="role"
                                    />
                                </label>
                            </div>
                        )}
                        <div className="col-12">
                            <label className="label-form">
                                <small className="field-name">Referido por:</small>
                                <AsyncSelect
                                    classNamePrefix="react-select"
                                    styles={customStyles}
                                    defaultValue={props.returnValueSelected('referers', props.fields.referer)}
                                    placeholder="Buscar referidor"
                                    noOptionsMessage={() => null}
                                    noOptionsMessage={() => 'No existe registro'}
                                    loadOptions={(inputValue, callback) => props.loadData(inputValue, callback, 'referer')}
                                    defaultOptions
                                    onChange={props.handleChangeReferer}
                                />
                            </label>
                        </div>
                    </>
                )}

                {advanced_profile && (!props.fields.type.includes(4) || props.fields.type.length > 1) && (
                    <div className="col-12">
                        <label className="label-form">
                            <small className="field-name">Número de cuenta</small>
                            <input autoComplete="off" onChange={props.handleChangeField} value={props.fields.bankAccountNumber} id="bankAccountNumber" type="text" className="form-control" />
                        </label>
                    </div>
                )}
                <div className="col-12">
                    <label className="label-form">
                        <small className="field-name">Notas del {props.state.title.toLowerCase()}</small>
                        <textarea id="observation" className="form-control" value={props.fields.observation} onChange={props.handleChangeField}></textarea>
                    </label>
                </div>
                {advanced_profile && (
                    <div className="col-12">
                        <label className="label-form">
                            <small className="field-name">Adjuntar documentos</small>
                            <InputFile onChange={props.changeFiles} />
                        </label>
                    </div>
                )}
                <div className="col-12">
                    <a className="mx-3" onClick={() => setAdvanced_profile(!advanced_profile)}>
                        <small style={{ color: '#1081c7' }}>{!advanced_profile ? 'Avanzado' : 'Rápido'}</small>
                    </a>
                </div>
                <div className="col-12 text-right">
                    <a
                        className="cancel-button"
                        onClick={() => {
                            props.cleanFields()
                            props.onClose()
                        }}>
                        Cancelar
                    </a>
                    <button className="create-button" data-step="ContactParticipantInfo" onClick={props.setStep}>
                        Siguiente
                    </button>
                </div>
            </div>
        </ContentParticipantInfo>
    )
}

const ContentParticipantInfo = styled.div`
    .label-form {
        margin-bottom: 0px;
        padding: 3px 0px;
    }
`
