import Cookies from 'universal-cookie'
const cookies = new Cookies()

export const Login = (state, action) => {
    let login = true
    let permissions = JSON.parse(action.data).permissions
    let serieInvoice = JSON.parse(action.data).serieInvoice
    let serieBudget = JSON.parse(action.data).serieBudget
    let serieRectified = JSON.parse(action.data).serieRectified
    let cid = JSON.parse(action.data).cid
    return Object.assign({}, state, { login, permissions, serieInvoice, serieBudget, serieRectified, cid })
}

export const Logout = (state, action) => {
    cookies.remove('token', { path: '/' })
    cookies.remove('tokeng', { path: '/' })
    cookies.remove('csrf', { path: '/' })
    cookies.remove('email', { path: '/' })
    cookies.remove('name', { path: '/' })
    cookies.remove('id', { path: '/' })
    cookies.remove('cid', { path: '/' })
    cookies.remove('company', { path: '/' })
    cookies.remove('key', { path: '/' })
    let login = false
    return Object.assign({}, state, { login })
}
